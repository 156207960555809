import { BsCheckLg } from 'react-icons/bs'
import '../../css/CustomCheckbox.css'
import { ReactNode } from 'react'

interface CustomCheckboxProps{
    value: boolean
    onChange: (val: boolean) => void
    text?: string
    className?: any
    title?: string
    type?: string 
    disabled?: boolean
    icon?: ReactNode
}

export default function CustomCheckbox({ value, onChange, text, className, title, type = "checked", disabled, icon }: CustomCheckboxProps) {



    return (
        <div className={`checkbox ${className ? className : ''} ${disabled ? "disabled" : ""}`} aria-disabled={disabled}  title={title} >
            <div className={`checkbox-box ${type === "prechecked" ? "prechecked" : ""} ${value && type==="checked" ? 'checkbox-box-checked' : ''}`}>
           
                  <BsCheckLg size={12} onClick={e => onChange(!value)} />
             
            </div>
            {icon ? <span className='mr-1' onClick={e => onChange(!value)}>{icon}</span> : null}
            <h2 className={`${value && !disabled ? 'checkbox-checked' : ''} ${disabled ? "opacity-50" : ""}`} onClick={e => onChange(!value)}>{text}</h2>
        </div>
    )
}
