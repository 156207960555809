import React from "react";
import { HiChevronDown, HiChevronUp, HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { ActionTemplate } from "../../types/template_types";

const ActionTemplateColumnShowHide = ({tmpReport, setTmpReport}: {tmpReport: ActionTemplate, setTmpReport: React.Dispatch<React.SetStateAction<ActionTemplate | undefined>>}) => {

    const hideColumn = (column: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.shown_columns = tmp.shown_columns.filter(v => v !== column)
        setTmpReport(tmp)
    }

    const showColumn = (column: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.shown_columns.push(column)
        setTmpReport(tmp)
    }

    const moveColumn = (index: number, direction: "up" | "down") => {
        if (tmpReport === undefined) return
        if ((direction === "up" && index === 0) || (direction === "down" && index === tmpReport.shown_columns.length - 1)) return
        let tmp = structuredClone(tmpReport)
        const newIndex = direction === "up" ? index - 1 : index + 1
        const column = tmp.shown_columns[index]
        tmp.shown_columns.splice(index, 1)
        tmp.shown_columns.splice(newIndex, 0, column)
        setTmpReport(tmp)
    }

return <div className='flex gap-3'>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Hidden columns</span>
            <div className='flex flex-col rounded bg-white p-5 w-[400px] h-[350px] overflow-y-auto border'>
                {tmpReport.columns.filter(c => !tmpReport.shown_columns.some(x => c === x)).map((c, i) => {
                    return <div key={i} className='flex justify-between border p-1 text-sm items-center'>
                        <span>{c}</span>
                        <HiOutlineEye onClick={() => showColumn(c)} className='w-5 h-5 stroke-gray-400 hover:stroke-black cursor-pointer' title='Show column' />
                    </div>
                })}
            </div>
        </div>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Shown columns</span>
            <div className='flex flex-col rounded bg-white p-5 w-[400px] h-[350px] border'>
                {
                    tmpReport.shown_columns.map((c, i) => {
                        return <div key={i} className='flex justify-between border p-1 text-sm items-center'>
                            <div className='flex items-center'>
                                <HiChevronUp onClick={() => moveColumn(i, "up")} className={`w-5 h-5 ${i === 0 ? 'fill-gray-300' : 'fill-gray-400 cursor-pointer hover:fill-black'}`} />
                                <HiChevronDown onClick={() => moveColumn(i, "down")} className={`w-5 h-5 ${i === tmpReport.shown_columns.length - 1 ? 'fill-gray-300' : 'fill-gray-400 cursor-pointer hover:fill-black'}`} />
                                <span>{c}</span>
                            </div>
                            <HiOutlineEyeOff onClick={() => hideColumn(c)} className='w-5 h-5 stroke-gray-400 hover:stroke-black cursor-pointer' title='Hide column' />
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

export default ActionTemplateColumnShowHide
