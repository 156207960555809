import { useEffect, useMemo, useState } from "react"
import { Outlet, Route, Routes, useNavigate, useParams, useMatch } from "react-router-dom"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext"
import { getRaw } from "../helpers/Requests"
import { APAAccount, ServerData } from "../types/types"
import { CloudOrgInfo } from "../types/cloud_types"
import TabBar from "../components/TabBar"
import UserOverview from "../components/ManageOrg/UserOverview"
import APAOrganization from "./APAAccount"
import ReportingOrganization from "./ReportingOrganization"
import { useStore } from "../contexts/StoreContext"
import APAOrgWithoutAccount from "./APAOrgWithoutAccount"
import OrganizationTemplateAccess from "./OrganizationTemplateAccess"
import OrganizationBucketSetup from "./OrganizationBucketSetup"
import { OrganizationBucketSetupEditorWrapper } from "./OrganizationBucketSetupEditor"

type OrganizationProps = {
    servers: ServerData[]
}

const Organization = (props: OrganizationProps) => {
    const { orgId } = useParams()
    const id = Number(orgId)
    const { orgs } = useStore()

    //const [org, setOrg] = useState(orgs[orgs.findIndex(o => o.id === id)])

    const org = useMemo(() => orgs[orgs.findIndex(o => o.id === id)], [orgs])

    const { setUp } = useSideBarMenuContext()

    useEffect(() => {
        setUp("/organizations")
    }, [id, setUp])

    return <div className="flex flex-col content-center ">
        <Routes>
            <Route element={<OrganizationWrapper org={org} servers={props.servers}/>} >
                {
                    org &&
                        <>
                    <Route path="" element={<UserOverview org={org} />} />
                    <Route path="setups/:serverId/:accountID" element={<APAOrganization />} />
                <Route path="setups/:serverId" element={<APAOrgWithoutAccount />} />
                    <Route path="reporting" element={<ReportingWrapper orgID={id} servers={props.servers.filter(s => s.type === "reporting")}/>}/>
                    <Route path="buckets/:bucketId" element={<OrganizationBucketSetupEditorWrapper />} />
                    <Route path="buckets" element={<OrganizationBucketSetup org={org} />} />
                    <Route path="templates" element={<OrganizationTemplateAccess org={org} />}/>
                    </>
            }
            </Route>
        </Routes>
    </div>
}
export default Organization

export function OrganizationWrapper(props:OrganizationProps & {org: CloudOrgInfo}) {
    const tabs = [{label: "Users", path: ""}, {label: "APA Setup", path: "setups"}, {label: "Reporting Service", path: "reporting"}, {label: "Buckets", path: "buckets"}, {label: "Template Access", path: "templates"}]

    const matches = useMatch("organizations/:cloudId/:orgId/:tab/*")

    //const [tab, setTab] = useState(0)
    const [account, setAccount] = useState<APAAccount | null>(null)
    const [server, setServer] = useState<ServerData | null>(null)
    const navigate = useNavigate();

    const setTab = (tab: number) => {
        const t = tabs[tab]

        if (t.path === "setups") {
            if (!account) {

                props.servers.forEach(server => {
                    if (server.type === "apa") {
                        getRaw('apa/' + server.id + '/accounts/organization/' + props.org.id)
                            .then((res) => {
                                if (res.status === 204) {
                                    return
                                } else if (res.status === 200) {
                                    return res.json()
                                }

                            }).then(data => {
                                if (data) {
                                    setAccount(data)
                                    setServer(server)
                                    navigate("setups/" + server.id + "/" + data.id);
                                } else {
                                    navigate("setups/" + server.id);
                                }

                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                })

            } else {
                navigate("setups/" + server?.id + "/" + account.id);
            }

            return
        }

        navigate(t.path)
    }

    const tab = Math.max(tabs.findIndex(t => t.path === matches?.params.tab), 0)

    // useEffect(() => {
    //     if(location.pathname === `/organizations/${org.id}`) {
    //         setTab(0)
    //     }
    // }, [location]);

    return (
        <div className="flex flex-col content-center ">
            <TabBar style={{ marginBottom: 10 }} selected={tab} tabs={tabs.map(t => t.label)} setTab={setTab} />
            <Outlet />
        </div>
    )
}

type ReportingWrapperProps = {
    servers: ServerData[], // server data for servers with the type 'reporting'
    orgID: number,
}
export function ReportingWrapper(props: ReportingWrapperProps) {
    return (
        <>
            {props.servers.map((s) => <ReportingOrganization key={s.id} serverId={s.id} orgId={props.orgID} />)}
        </>
    )
}
