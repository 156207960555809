import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { CloudDashboard, CloudDashboardMap, CloudReport, CloudReportMap, CloudUser, CloudUserMap, Consultant, MailBundleWithRelations } from "../types/cloud_types";
import { CloudOrgWithReporting, Plan } from "../types/reporting_types";
import { ServerData } from "../types/types";
import AddMailBundle from "../components/Reporting/AddMailBundle";
import { useParams } from "react-router-dom";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { delBody, delVoid, get, post, put, putNoBody } from "../helpers/Requests";
import { Table, TableBody, TableHead, TableHeadCell, TableHeadRow } from "../components/Table";
import Button from "../components/Button";
import { getMailBundleStats } from "../helpers/reporting_helpers";
import MenuDropdown from "../components/MenuDropdown";
import { HiDotsCircleHorizontal, HiTrash } from "react-icons/hi";
import MenuDropdownItem from "../components/MenuDropdownItem";
import { FiSend } from "react-icons/fi";
import { getDateString } from "../helpers/general_helpers";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import MultiSelect from "../components/MultiSelect";
import { MdClose, MdOutlinePlayArrow, MdOutlinePlayDisabled } from "react-icons/md";
import ReportingFilterBar from "../components/Reporting/ReportingFilterBar"
import { FaClock, FaTrash, FaUser } from "react-icons/fa";
import { IoDuplicate } from "react-icons/io5";
import MultiSelectBar from "../components/MultiSelectBar";
import CustomCheckbox from "../components/Buttons/CustomCheckbox";
import ReportingModal from "../components/Reporting/ReportingModal";
import { PiWarningBold } from "react-icons/pi";

interface ReportingMailBundlesProps {
    server: ServerData | undefined;
    reporting_mail_bundles: MailBundleWithRelations[];
    plans: Plan[];
    users: CloudUser[];
    consultants: Consultant[];
    mailBundleIDsSending: number[];
    setMailBundleIDsSending: Dispatch<SetStateAction<number[]>>
    update: () => any;
    sendmail: (mb: MailBundleWithRelations, test: boolean) => void;
    org: CloudOrgWithReporting | undefined
    sendMultipleMails: (mbs:MailBundleWithRelations[]) => void
    showAddModal: boolean
    setShowAddModal: Dispatch<SetStateAction<boolean>>
}

function ReportingMailBundles(props:ReportingMailBundlesProps){
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const [cloudDashboards, setCloudDashboards] = useState<CloudDashboard[]>([]);
    const [cloudDashboardsMap, setCloudDashboardsMap] = useState<CloudDashboardMap>()
    const [cloudReports, setCloudReports] = useState<CloudReport[]>([])
    const [cloudReportsMap, setCloudReportsMap] = useState<CloudReportMap>()
    
    const [selectedMailBundle, setSelectedMailBundle] = useState<MailBundleWithRelations | undefined>();
    const [initialEditPage, setInitialEditPage] = useState<0 | 1 | 2 | 3>(0)

    const [showTestMailModal, setShowTestMailModal] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const [sortConfig, setSortConfig] = useState<{direction: "asc" | "desc", key: keyof MailBundleWithRelations | "items" | "recipients"}>({direction: "desc", key: "id"})
    const [shownMailBundles, setShownMailBundles] = useState<MailBundleWithRelations[]>([])

    const [searchText, setSearchText] = useState<string>("");
    const [filteredMailBundles, setFilteredMailBundles] = useState<MailBundleWithRelations[]>([]);

    const [planFilter, setPlanFilter] = useState<"D" | "W" | "M" | "A">("A")
    const [userFilter, setUserFilter] = useState<number|undefined>()
    const [subscriptionTypeFilter, setSubscriptionTypeFilter] = useState<"BUNDLE" | "SUBSCRIPTION" |"A" >("A")

    const [checkedRows, setCheckedRows] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false);
    let checkedBundleText = checkedRows.length > 1 ? "bundles" : "bundle"
    const [showMultiPlanModal, setShowMultiPlanModal] = useState<boolean>(false)
    const [showMultiDeleteConfirmation, setShowMultiDeleteConfirmation] = useState<boolean>(false)
    const [showMultiSendModal, setShowMultiSendModal] = useState<boolean>(false)
    const multiSelectOptions = [
        {
            label: (
                <span>
                    <FiSend className="inline-block mr-1"/>
                    Send mail to <strong>{checkedRows.length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setShowMultiSendModal(true)
        },
        {
            label: (
                <span>
                    <FaClock className="inline-block mr-1"/>
                    Change schedule for <strong>{checkedRows.length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setShowMultiPlanModal(true)
        },
        {
            label: (
                <span className="text-red-500">
                    <FaTrash className="inline-block mr-1"/>
                    Delete <strong>{checkedRows.length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setShowMultiDeleteConfirmation(true),
        },
    ]
    if(shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => mb.active).length > 0){
        multiSelectOptions.splice(1, 0, {
            label: (
                <span>
                    <MdOutlinePlayDisabled className="inline-block mr-1"/>
                    Deactivate <strong>{shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => mb.active).length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setActiveMultiple(checkedRows, false),
        })
    }
    if(shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => !mb.active).length > 0){
        multiSelectOptions.splice(1, 0, {
            label: (
                <span>
                    <MdOutlinePlayArrow className="inline-block mr-1"/>
                    Activate <strong>{shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => !mb.active).length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setActiveMultiple(checkedRows, true),
        })
    }

    const userMap:CloudUserMap = useMemo(() => {
        return props.users
            .filter(u => !u.deleted)
            .reduce((acc:CloudUserMap, cur) => {
                if(!cur.id) return acc;
                acc[cur.id] = cur; 
                return acc
            }, {})
    }, [props.users])

    const sortedTableData = useMemo(() => {
        let sortableTableData = [...shownMailBundles]
        if (sortConfig !== null) {
            sortableTableData.sort((a, b) => {
                let result = 0
                if(sortConfig.key === "items"){
                    result = getTotalItemsAmount(a) - getTotalItemsAmount(b)
                } else if (sortConfig.key === "recipients"){
                    result = a.user_ids.length - b.user_ids.length
                } else if (typeof a[sortConfig.key] === "number" && typeof b[sortConfig.key] === "number") {
                    result = Number(a[sortConfig.key]) - Number(b[sortConfig.key])
                } else if (a[sortConfig.key] === ""){
                    result = 1
                } else if (b[sortConfig.key] === ""){
                    result = -1
                } else {
                    result = String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]), undefined, { numeric: true, sensitivity: "base" })
                }
                return sortConfig.direction === "asc" ? result : -result
            })
        }
        return sortableTableData
    }, [sortConfig, shownMailBundles])
    
    useEffect(() => {
        setSelectAll(false)
        setCheckedRows([])
        setShownMailBundles(props.reporting_mail_bundles)
        setFilteredMailBundles(props.reporting_mail_bundles)
    }, [props.reporting_mail_bundles])

    useEffect(() => {
        search(searchText)
    }, [filteredMailBundles, searchText])

    useEffect(() => {
        filterMailBundles(props.reporting_mail_bundles)
    }, [planFilter, userFilter, subscriptionTypeFilter])

    useEffect(() => {
        setUp(`/reporting/${orgId}`)
        if(props.server?.cloud){
            get(`cloud/${props.server?.cloud}/dashboards/${orgId}`) // cloud dashboards are retrieved in order to display dashboard name in the table
                .then((res: CloudDashboard[]) => {
                    // making an object of all dashboards with id as key for faster lookup when rendering in table
                    const _tmp: CloudDashboardMap = res.reduce((acc: CloudDashboardMap, cur) => { acc[cur.id] = cur; return acc }, {})
                    setCloudDashboardsMap(_tmp)
                    setCloudDashboards(res)
                })
                .catch(err => console.log(err))
            
            get(`cloud/${props.server?.cloud}/reports/${orgId}`) // cloud reports are retrieved in order to display report name in the table
                .then((res: CloudReport[]) => {
                    // making an object of all reports with id as key for faster lookup when rendering in table
                    const _tmp: CloudReportMap = res.reduce((acc: CloudReportMap, cur) => { acc[cur.report_id] = cur; return acc }, {})
                    setCloudReportsMap(_tmp)
                    setCloudReports(res)
                })
                .catch(err => console.log(err))
        }
    }, [orgId, setUp, props.server])

    function filterMailBundles(mailBundles : MailBundleWithRelations[]){
        if(mailBundles.length === 0) return;
        let filtered = [...mailBundles]

        if(subscriptionTypeFilter === "SUBSCRIPTION"){
            filtered = filtered.filter(mb => mb.self_subscription)
        } else if(subscriptionTypeFilter === "BUNDLE"){
            filtered = filtered.filter(mb => !mb.self_subscription)
        }
        
        switch(planFilter){
            case "D":
            case "W":
            case "M":
                filtered = filtered.filter(m => m.plan.startsWith(planFilter))
                setFilteredMailBundles(filtered)
                break;
            case "A":
            default:
                // do nothing
        }
        if(userFilter){
            filtered = filtered.filter(m => m.user_ids.includes(userFilter))
        }
        setFilteredMailBundles(filtered)
        if(!searchText) setShownMailBundles(filtered)
    }

    const onEdit = (m : MailBundleWithRelations, initialPage?: 0 | 1 | 2 | 3) => {
        if(initialPage){
            setInitialEditPage(initialPage)
        } else {
            setInitialEditPage(0);
        }
        setSelectedMailBundle(m)
        props.setShowAddModal(true);
    }

    const onDelete = (id : number) => {
        delVoid(`cloud/${props.server?.cloud}/mailbundle/${id}`)
            .then(() => {
                props.update()
            })
            .catch(err => console.log(err))
    }

    const onSendMail = (m : MailBundleWithRelations, test : boolean) => {
        if(!test && !m.active){
            onToggleActive(m)
        }
        props.sendmail(m, test)
    }

    const onSendMultiple = (mbs: MailBundleWithRelations[]) => {
        props.sendMultipleMails(mbs)
    }

    function setActiveMultiple (ids: number[], activate: boolean){
        if(activate){
            put(`cloud/${props.server?.cloud}/bulk/mailbundle/activate`, ids).then(res => {
                props.update();
            }).catch(err => console.log(err))
        } else {
            put(`cloud/${props.server?.cloud}/bulk/mailbundle/deactivate`, ids).then(res => {
                props.update();
            }).catch(err => console.log(err))
        }
    }

    function deleteMultiple (ids: number[]) {
        delBody(`cloud/${props.server?.cloud}/bulk/mailbundle`, ids)
            .then(() => {
                props.update();
            }).catch(err => console.log(err))
    }

    function updatePlanMultiple (ids: number[], plan: Plan){
        put(`cloud/${props.server?.cloud}/bulk/mailbundle/plan/${plan.interval}/${plan.id}`, ids)
            .then(() => {
                props.update();
            }).catch(err => console.log(err))
    }

    const onDuplicate = (m : MailBundleWithRelations) => {
        m.id = 0
        m.name = m.name + " - Copy"
        post(`cloud/${props.server?.cloud}/mailbundle`, m).then(res => {
            props.update();
        }).catch(err => console.log(err))
    }

    const requestSort = (key: keyof MailBundleWithRelations | "items" | "recipients") => {
        let direction : "asc" | "desc" = "asc"
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc"
        }
        setSortConfig({ key, direction })
    }

    const getSortDirectionIcon = (key: keyof MailBundleWithRelations | "items" | "recipients") => {
        if (sortConfig && sortConfig.key === key) {
            return sortConfig.direction === "asc" ? <BiSortUp className="inline-block ml-1"/> : <BiSortDown className="inline-block ml-1"/>
        }
        return ""
    }

    const search = (query:string) => {
        if (!query || query.length < 1) {
            setShownMailBundles(filteredMailBundles)
            return
        }

        const filtered = filteredMailBundles.filter(searchFilterFunc)
        setShownMailBundles(filtered)
    }

    const searchFilterFunc = (m: MailBundleWithRelations) => {
        const nameMatch = m.name.toLowerCase().includes(searchText.toLowerCase())
        let recipientsMatch = m.user_ids
            .map(id => props.users.find(u => u.id === id))
            .filter(u => !!u)
            .some(u => (u?.firstname + " " + u?.lastname).toLowerCase().includes(searchText.toLowerCase()))
        
        let reportsMatch = false;
        let dashboardsMatch = false;
        if(!!cloudReportsMap && !!cloudDashboardsMap){
            reportsMatch = [...m.report_ids, ...m.insight_views.map(tv => tv.report_id)]
                .map(id => cloudReportsMap[id])
                .filter(r => !!r)
                .some(r => r?.name.toLowerCase().includes(searchText.toLowerCase()))
            
            dashboardsMatch = m.dashboards
                .map(d => cloudDashboardsMap[d.dashboard_id])
                .filter(d => !!d)
                .some(d => d.title.toLowerCase().includes(searchText.toLowerCase()))
        }       
        return nameMatch || recipientsMatch || reportsMatch || dashboardsMatch
    }

    const onToggleActive = (m: MailBundleWithRelations) => {
        putNoBody(`cloud/${props.server?.cloud}/mailbundle/${m.id}/active`)
            .then(() => {
                props.update()
            })
            .catch(err => console.log(err))
    }

    const getPlan = (plan_id: number): string => {
        var plan = props.plans.find(x => x.id === plan_id);
        if(plan !== undefined)
            return plan.description.substring(4).replace("on the ", "")
        else
            return "??"
    }

    const isSending = (mb: MailBundleWithRelations) => {
        return props.mailBundleIDsSending.includes(mb.id)
    }

    function getTotalItemsAmount(mb: MailBundleWithRelations) {
        return mb.dashboards.length + mb.insight_views.length + mb.report_ids.length
    }

    const handleSelectAll = (bool:boolean) => {
        if (bool) {
            setCheckedRows(shownMailBundles.map((mb) => mb.id))
            setSelectAll(true)
        } else {
            setCheckedRows([])
            setSelectAll(false)
        }
    }
    const handleCheck = (bool:boolean, mb: MailBundleWithRelations) => {
        if (bool) {
            setCheckedRows([...checkedRows, mb.id])
        } else {
            setCheckedRows(checkedRows.filter((id) => id !== mb.id))
        }
    }

    return (
        <div className="w-full">
            <ReportingFilterBar
                items={props.reporting_mail_bundles}
                shownItems={shownMailBundles}
                users={props.users}
                searchText={searchText}
                setSearchText={setSearchText}
                selectedPlan={planFilter}
                setSelectedPlan={setPlanFilter}
                userFilter={userFilter}
                setUserFilter={setUserFilter}
                setShowAddModal={props.setShowAddModal}
                stats={getMailBundleStats(props.reporting_mail_bundles, props.plans)}
                isMailbundle={true}
                subscriptionTypeFilter={subscriptionTypeFilter}
                setSubscriptionTypeFilter={setSubscriptionTypeFilter}
            />
            <Table>
                <TableHead className="rounded-table bg-dark-forest-900 text-white bg-opacity-75 cursor-pointer">
                <TableHeadRow className="select-none">
                    <TableHeadCell 
                        className="normal-case text-[14px]"
                    >
                        <CustomCheckbox 
                            value={selectAll} 
                            onChange={(bool) => handleSelectAll(bool)}
                        />
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("id")} className="w-16">
                        ID
                        {getSortDirectionIcon("id")}
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("name")}>
                        MAILBUNDLE NAME
                        {getSortDirectionIcon("name")}
                    </TableHeadCell>
                    <TableHeadCell>
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("items")} className="text-center">
                        Items
                        {getSortDirectionIcon("items")}
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("recipients")} className="text-center">
                        Recipients
                        {getSortDirectionIcon("recipients")}
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("plan")}>
                        PLAN
                        {getSortDirectionIcon("plan")}
                    </TableHeadCell>
                    <TableHeadCell onClick={() => requestSort("last_sent")}>
                        LAST SENT
                        {getSortDirectionIcon("last_sent")}
                    </TableHeadCell>
                    <TableHeadCell className="text-center" onClick={() => requestSort("link")}>
                        INCLUDE LINK
                        {getSortDirectionIcon("link")}
                    </TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                    <TableHeadCell className="text-center" onClick={() => requestSort("active")}>
                        STATUS
                        {getSortDirectionIcon("active")}
                    </TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                </TableHeadRow>
                </TableHead>
                <TableBody className="rounded-table text-sm">
                    {sortedTableData.length === 0 && <td colSpan={12} className="p-2 italic text-gray-400 text-center">No bundles found</td>}
                    {!!cloudDashboardsMap && !!cloudReportsMap && sortedTableData.map(m => {
                        return <tr key={m.id} className={`${m.active ? "even:bg-gray-50" : "bg-gray-300"} cursor-pointer hover:bg-gray-200`}>
                            <td className="px-4 py-2 whitespace-nowrap">
                                <CustomCheckbox 
                                    value={checkedRows.includes(m.id)} 
                                    onChange={(bool) => handleCheck(bool, m)} 
                                />
                            </td>
                            <td onClick={() => onEdit(m)} className="px-4">{m.id}</td>
                            <td className="px-4 2xl:max-w-[300px] xl:max-w-[200px] max-w-[100px]" onClick={() => onEdit(m)}>
                                <div className="overflow-hidden text-ellipsis whitespace-nowrap 2xl:max-w-[300px] xl:max-w-[200px] max-w-[100px]" title={m.name}>
                                    {m.name}
                                </div>
                            </td>
                            <td className="">
                                {m.self_subscription && <div 
                                    className="text-center text-gray-500 border border-gray-400 rounded-md font-semibold text-xs w-fit px-2" 
                                    onClick={() => subscriptionTypeFilter === "SUBSCRIPTION" ? setSubscriptionTypeFilter("A") : setSubscriptionTypeFilter("SUBSCRIPTION")}
                                    title="Personal Subscription"
                                >
                                    <FaUser className="mb-1 inline-block" />
                                </div>}
                            </td>
                            <td className="text-center font-semibold" onClick={() => onEdit(m)}>{getTotalItemsAmount(m)}</td>
                            <td 
                                className="text-center" 
                                onClick={() => onEdit(m, 2)} 
                                title={
                                    m.user_ids.length > 10 
                                    ? m.user_ids.slice(0, 10).map(id => userMap[id].firstname + " " + userMap[id].lastname).join("\n") + `\nAnd ${m.user_ids.length - 10} more...`
                                    : m.user_ids.map(id => userMap[id].firstname + " " + userMap[id].lastname).join("\n")
                                }
                            >
                                <span className="font-semibold mr-1">{m.user_ids.length}</span>
                                <FaUser className="text-dark-forest-800 inline-block" />
                            </td>
                            <td onClick={() => onEdit(m, 1)} className="px-2 whitespace-nowrap text-ellipsis overflow-hidden 2xl:max-w-[300px] xl:max-w-[200px] max-w-[100px]" title={getPlan(m.plan_id ?? -1)}>{getPlan(m.plan_id ?? -1)}</td>
                            <td onClick={() => onEdit(m)} className="px-2 whitespace-nowrap text-ellipsis overflow-hidden 2xl:max-w-[150px] xl:max-w-[100px] max-w-[50px]">{m.last_sent !== 0 ? getDateString(m.last_sent) : "-"}</td>
                            <td className="text-center" onClick={() => onEdit(m, 2)}>{m.link ? "Yes" : "No"}</td>
                            <td className="w-10 px-2">
                                <div  
                                    title={`${isSending(m) ? "" : m.last_sent !== 0 ? "Resend email":"Send email"}`}
                                    onClick={() => {if(!isSending(m)) onSendMail(m, false)}}
                                    className={`${isSending(m) ? "bg-green-500 text-white" : m.last_sent !== 0 && m.active ? "text-green-500 border-green-500 cursor-pointer" : "text-gray-500 border-gray-500 cursor-pointer"} border rounded-3xl px-2 h-6 w-fit`}
                                >
                                    {isSending(m) ? "Sending" : m.last_sent !== 0 && m.active ? "Resend" : "Send"}
                                </div>
                            </td> 
                            <td className="w-10 px-2">
                                <div  
                                    title={
                                        `${m.active 
                                            ? "Deactivate" 
                                            : m.self_subscription && !props.org?.self_subscription_enabled 
                                                ? "Activating will change this to a bundle" 
                                                : "Activate"}`
                                    } 
                                    onClick={() => onToggleActive(m)}
                                    className={`${m.active ? "border-green-500 text-white bg-green-500" : "text-gray-500 border-gray-500"} cursor-pointer border rounded-3xl px-2 w-fit h-6 flex items-center gap-1`} 
                                >
                                    {m.self_subscription && !props.org?.self_subscription_enabled &&
                                        <PiWarningBold className="text-base font-semibold text-yellow-600" />
                                    }
                                    {m.active ? "Activated" : "Activate"} 
                                </div>
                            </td>
                            <td className="w-10">
                                <MenuDropdown direction="up" color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-6 h-6 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
                                    <div className="px-1 py-1 ">
                                        <MenuDropdownItem onClick={() => {onDuplicate(m)}} text={"Duplicate"} icon={<IoDuplicate className="w-5 h-5 mr-2 text-blue-600 opacity-80" />} />
                                        <MenuDropdownItem onClick={() => {setSelectedMailBundle(m);setShowTestMailModal(true)}} text={"Send test mail"} icon={<FiSend className="w-5 h-5 mr-2 text-yellow-500 opacity-80" />} />
                                        <MenuDropdownItem onClick={() => {setSelectedMailBundle(m);setShowDeleteConfirmation(true);}} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
                                    </div>
                                </MenuDropdown>
                            </td>
                        </tr>
                    })}
                </TableBody>
            </Table>
            <MultiSelectBar 
                selected={checkedRows} 
                options={multiSelectOptions} 
                type="mailbundle" 
                onDeselectClick={() => {setCheckedRows([]); setSelectAll(false)}}
            />
            {props.showAddModal &&
                <AddMailBundle 
                    server={props.server} 
                    orgId={parseInt(orgId ?? "-1")} 
                    users={props.users}
                    cloudDashboards={cloudDashboards} 
                    cloudReports={cloudReports} 
                    onClose={() => {props.setShowAddModal(false); setSelectedMailBundle(undefined)}}
                    open={props.showAddModal}
                    plans={props.plans}
                    update={props.update}
                    selectedMailBundle={selectedMailBundle}
                    use_access_control={props.org?.use_access_control ?? false}
                    location_enabled={true}
                    initialPage={initialEditPage}
                />
            }
            {showDeleteConfirmation && selectedMailBundle &&
                <DeleteConfirmation
                    mailBundle={selectedMailBundle}
                    onClose={() => {setShowDeleteConfirmation(false); setSelectedMailBundle(undefined)}}
                    open={showDeleteConfirmation}
                    onDelete={onDelete}
                />
            }
            {showTestMailModal && selectedMailBundle &&
                <TestMailModal
                    mailBundle={selectedMailBundle}
                    onClose={() => {setShowTestMailModal(false); setSelectedMailBundle(undefined)}}
                    open={showTestMailModal}
                    onSendMail={(mb) => onSendMail(mb, true)}
                    consultants={props.consultants}
                    cloudUsers={props.users.filter(u => !u.deleted)}
                />
            }
            {showMultiDeleteConfirmation && checkedRows.length > 0 &&
                <MultiDeleteConfirmation
                    open={showMultiDeleteConfirmation}
                    mailBundles={props.reporting_mail_bundles.filter(m => checkedRows.includes(m.id))}
                    onClose={() => setShowMultiDeleteConfirmation(false)}
                    onDelete={deleteMultiple}
                />
            }
            {showMultiPlanModal && checkedRows.length > 0 &&
                <PlanMultiEditModal
                    open={showMultiPlanModal}
                    mailBundles={props.reporting_mail_bundles.filter(m => checkedRows.includes(m.id))}
                    onClose={() => setShowMultiPlanModal(false)}
                    onSave={updatePlanMultiple}
                    plans={props.plans}
                />
            }
            {showMultiSendModal && checkedRows.length > 0 &&
                <MultiSendModal
                    open={showMultiSendModal}
                    onClose={() => setShowMultiSendModal(false)}
                    onSend={onSendMultiple}
                    mailbundles={props.reporting_mail_bundles.filter(m => checkedRows.includes(m.id))}
                    userMap={userMap}
                />
            }
        </div>)
}

interface DeleteConfirmationProps {
    open: boolean
    mailBundle: MailBundleWithRelations
    onClose: () => void
    onDelete: (id: number) => void
}

function DeleteConfirmation(props:DeleteConfirmationProps){
    return (
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[500px] max-h-[200px]"
        >
            <div className="m-auto">
                <div className="text-xl">Are you sure you want to delete <span className="font-semibold">{props.mailBundle.name}</span>?</div>
                <div className="flex items-center justify-end gap-2 mt-10">
                    <Button 
                        color="secondary"
                        onClick={() => props.onClose()}
                        text="Cancel"
                    />
                    <Button 
                        color="alert"
                        onClick={() => {props.onDelete(props.mailBundle.id); props.onClose()}}
                        text="Delete"
                    />
                </div>
            </div>
        </ReportingModal>
    )
}

interface TestMailModalProps {
    open: boolean
    mailBundle: MailBundleWithRelations
    onClose: () => void
    onSendMail: (mb:MailBundleWithRelations) => void
    consultants: Consultant[]
    cloudUsers: CloudUser[]
}

function TestMailModal(props:TestMailModalProps){
    const [selectedRecipientIDs, setSelectedRecipientIDs] = useState<number[]>([])

    const recipients = useMemo(() => props.cloudUsers.filter(u => props.mailBundle.user_ids.includes(u.id ?? -1)), [props.mailBundle])
    const selectedConsultants = useMemo(() => props.consultants.filter(c => selectedRecipientIDs.includes(c.id)), [selectedRecipientIDs, props.consultants])
    const selectedRecipients = useMemo(() => recipients.filter(r => selectedRecipientIDs.includes(r.id ?? -1)), [selectedRecipientIDs, props.cloudUsers, props.mailBundle])

    const sendTest = () => {
        const testMailBundle : MailBundleWithRelations = {
            ...props.mailBundle,
            user_ids: selectedRecipientIDs
        }
        props.onSendMail(testMailBundle)
    }

    return (
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[700px] max-h-[700px]"
        >
            <div className="text-xl font-semibold">Send test mail <span className="font-semibold text-gray-400">{props.mailBundle.name}</span></div>
            <div className="flex">
                <div className="w-full h-[400px] p-2 flex flex-col gap-8">
                    <div className="font-semibold text-base">Select recipients for test mail</div>
                    <div className="font-semibold text-sm">Consultants    
                        <MultiSelect
                            onOptionSelect={(o) => setSelectedRecipientIDs([...selectedRecipientIDs, o.value])}
                            onOptionDeselect={(o) => setSelectedRecipientIDs(selectedRecipientIDs.filter(id => id !== o.value))}
                            options={
                                props.consultants.map(c => ({
                                    value: c.id,
                                    label: c.firstname + " " + c.lastname
                                }))
                            }
                            placeholder="Select consultants..."
                            selectedOptions={selectedConsultants
                                .map(c => {
                                    return {
                                        label: c.firstname + " " + c.lastname,
                                        value: c.id
                                    }
                                })
                            }
                        />
                    </div>
                    <div className="font-semibold text-sm">Mailbundle recipients
                        <MultiSelect
                            onOptionSelect={(o) => setSelectedRecipientIDs([...selectedRecipientIDs, o.value])}
                            onOptionDeselect={(o) => setSelectedRecipientIDs(selectedRecipientIDs.filter(id => id !== o.value))}
                            options={
                                recipients.map(r => ({
                                    value: r.id,
                                    label: r.firstname + " " + r.lastname
                                }))
                            }
                            placeholder="Select recipients..."
                            selectedOptions={selectedRecipients
                                .map(r => {
                                    return {
                                        label: r.firstname + " " + r.lastname,
                                        value: r.id
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <div className="w-full border-l p-2 flex flex-col gap-4 overflow-y-auto h-[450px]">
                    {selectedConsultants.length > 0 && <div className="font-semibold mr-1 text-sm">Selected Consultants
                        {selectedConsultants.map(c => {
                            return (
                                <div 
                                    key={c.id} 
                                    className="p-1.5 border-b  hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full font-normal" 
                                    onClick={() => setSelectedRecipientIDs(selectedRecipientIDs.filter(id => id !== c.id))}
                                >
                                    <span className="flex items-center gap-1">
                                        <span>
                                            {c.firstname + " " + c.lastname}
                                        </span>
                                    </span>
                                    <MdClose/>
                                </div>
                            )
                        })}    
                    </div>}
                    {selectedRecipients.length > 0 && <div className="font-semibold mr-1 text-sm">Selected Recipients
                        {selectedRecipients.map(r => {
                            return (
                                <div 
                                    key={r.id} 
                                    className="p-1.5 border-b  hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full font-normal" 
                                    onClick={() => setSelectedRecipientIDs(selectedRecipientIDs.filter(id => id !== r.id))}
                                >
                                    <span className="flex items-center gap-1">
                                        <span>
                                            {r.firstname + " " + r.lastname}
                                        </span>
                                    </span>
                                    <MdClose/>
                                </div>
                            )
                        })}    
                    </div>}
                </div>
            </div>
            <div className="flex items-center justify-end gap-2 border-t min-h-[100px]">
                <Button
                    color="secondary"
                    text="Cancel"
                    onClick={() => props.onClose()}
                />
                <Button
                    color="primary"
                    text="Send"
                    onClick={() => {sendTest(); props.onClose()}}
                />
            </div>
        </ReportingModal>
    )
}

interface MultiDeleteConfirmationProps{
    open: boolean
    mailBundles: MailBundleWithRelations[]
    onClose: () => void
    onDelete: (mIDs: number[]) => void
}

function MultiDeleteConfirmation(props:MultiDeleteConfirmationProps){
    return (<>
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[500px] max-h-[200px]"
        >
            <div className="m-auto w-full">
                <div className="text-xl font-semibold">You are about to delete the following bundles</div>
                <div>
                    {props.mailBundles.map((m, i) => {
                        return <div key={i} className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full" title={m.name}>{m.name}</div>
                    })}
                </div>
                <div className="flex items-center justify-end gap-2 mt-10">
                    <Button 
                        color="secondary"
                        onClick={() => props.onClose()}
                        text="Cancel"
                    />
                    <Button 
                        color="alert"
                        onClick={() => {props.onDelete(props.mailBundles.map(m => m.id)); props.onClose()}}
                        text="Delete"
                    />
                </div>
            </div>
        </ReportingModal>
    </>)
}

interface PlanMultiEditModalProps {
    plans: Plan[]
    mailBundles: MailBundleWithRelations[]
    open: boolean
    onClose: () => void
    onSave: (ids: number[], plan: Plan) => void
}

function PlanMultiEditModal(props:PlanMultiEditModalProps){
    const [selectedPlan, setSelectedPlan] = useState<Plan>()

    return (
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[900px] max-h-[800px]"
        >
                <div className="text-3xl w-full border-b p-2">Update schedule for {props.mailBundles.length} {props.mailBundles.length > 1 ? "bundles" : "bundle"}</div>
                <div className="flex">
                    <div className="p-2 flex flex-col gap-2 border-r w-52 overflow-y-auto h-[600px]">
                        <div className="font-semibold">Bundles to update</div>
                        {props.mailBundles.map((m, i) => {
                            return <div key={i}>{m.name}</div>
                        })}
                    </div>
                    <div>

                    </div>
                    <div className="flex justify-evenly p-2 gap-2 overflow-y-auto h-[600px]">
                        <div className="flex flex-col gap-2 items-center">
                            <div className="text-lg w-full text-center font-semibold">Daily</div>    
                            {props.plans
                                .filter(p => p.interval.startsWith("D"))
                                .map(p => (
                                    <div 
                                        key={p.id} 
                                        className={selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                        onClick={() => setSelectedPlan(p)}
                                    >
                                        {p.description.substring(4)}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="flex flex-col gap-2 items-center">
                            <div className="text-lg w-full text-center font-semibold">Weekly</div>
                            {props.plans
                                .filter(p => p.interval.startsWith("W"))
                                .map(p => (
                                    <div 
                                        key={p.id} 
                                        className={selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                        onClick={() => setSelectedPlan(p)}
                                    >
                                        {p.description.substring(4)}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="flex flex-col gap-2 items-center">
                            <div className="text-lg w-full text-center font-semibold">Monthly</div>
                            {props.plans
                                .filter(p => p.interval.startsWith("M"))
                                .map(p => (
                                    <div 
                                        key={p.id} 
                                        className={selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                        onClick={() => setSelectedPlan(p)}
                                    >
                                        {p.description.substring(4)}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            <div className="flex gap-2 w-full justify-end items-center pb-2 px-2 pt-4 border-t">
                <Button text="Close" color="default" onClick={props.onClose} />
                <Button text="Save" disabled={!selectedPlan} color="primary" onClick={() => {if(!!selectedPlan) {props.onSave(props.mailBundles.map(m => m.id), selectedPlan); props.onClose()}}} />
            </div>
        </ReportingModal>
    )
}


interface MultiSendModalProps {
    open: boolean
    onClose: () => void
    onSend: (mbs: MailBundleWithRelations[]) => void
    userMap: CloudUserMap
    mailbundles: MailBundleWithRelations[]
}

function MultiSendModal(props:MultiSendModalProps) {
    const mailCount = props.mailbundles.flatMap(m => m.user_ids).length
    return (
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[900px] max-h-[800px]"
        >
            <div className="text-3xl p-2 border-b">Are you sure you want to send {mailCount} mails?</div>
            <div className="max-h-[550px] overflow-y-auto flex flex-col gap-1">
                {props.mailbundles.map((m, i) => {
                    return (
                        <div className="p-1 rounded bg-gray-100 flex items-start gap-1">
                            <div className="font-semibold whitespace-nowrap text-sm">{m.name} <span className="font-normal">to</span></div>
                            {m.user_ids.map(id => props.userMap[id].firstname + " " + props.userMap[id].lastname).join(", ")}
                        </div>
                    )
                })}
            </div>
            <div className="flex items-center justify-end w-full border-t pt-4 gap-2">
                <Button text="Close" color="default" onClick={props.onClose} />
                <Button text="Send" color="primary" onClick={() => {props.onSend(props.mailbundles); props.onClose()}} />
            </div>
        </ReportingModal>
    )
}

export default ReportingMailBundles;