import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { FaSave, FaUndo } from 'react-icons/fa'
import { HiChevronDown } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../components/Button'
import CustomCheckbox from '../components/Buttons/CustomCheckbox'
import CustomInput from '../components/CustomInput'
import InputWithSuggestions from '../components/InputWithSuggestions'
import { Modal, WarningModal } from '../components/Modals'
import SearchableSelect from '../components/SearchableSelect'
import TabBar from '../components/TabBar'
import ReportTemplateColumnSelect from '../components/Template/ReportTemplateColumnSelect'
import ReportTemplateColumnShowHide from '../components/Template/ReportTemplateColumnShowHide'
import ReportTemplateKpiConfig from '../components/Template/ReportTemplateKpiConfig'
import ReportTemplateMatrixConfig from '../components/Template/ReportTemplateMatrixConfig'
import ReportTemplateSlicerSetup from '../components/Template/ReportTemplateSlicerSetup'
import ReportTemplateViewSettings from '../components/Template/ReportTemplateViewSettings'
import { del, get, put } from '../helpers/Requests'
import { compareObjects } from '../helpers/type_helpers'
import { BucketTemplate, ReportTemplate, SortDirection } from '../types/template_types'
const TemplateReportEdit = () => {
    const { cloudId, reportId } = useParams()
    const navigate = useNavigate();
    const [report, setReport] = useState<ReportTemplate>()
    const [tmpReport, setTmpReport] = useState<ReportTemplate>()
    const [bucket, setBucket] = useState<BucketTemplate>()

    const [defaultGroups, setDefaultGroups] = useState<string[]>()

    const [showDeleteTemplateWarning, setShowDeleteTemplateWarning] = useState(false)

    const [tmpName, setTmpName] = useState("")
    const [showEditName, setShowEditName] = useState(false)
    const [tmpDesc, setTmpDesc] = useState("")

    const [tab, setTab] = useState(0)

    const [tmpLimit, setTmpLimit] = useState(``)

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        get(`cloud/${cloudId}/templates/report/defaultGroups`)
        .then((res: string[]) => {
                setDefaultGroups(res)
            }).catch(e => console.log(e))
    }, [])

    useEffect(() => {
        get(`cloud/${cloudId}/templates/report/${reportId}`)
            .then((r: ReportTemplate) => {
                setReport(r)
            }).catch(e => console.log(e))

    }, [cloudId, reportId])

    useEffect(() => {
        setTmpReport(report)
        setTmpLimit(report?.limit !== undefined && report.limit > -1 ? `${report.limit}` : "")

        if (report === undefined) return
        get("cloud/" + cloudId + "/templates/bucket/" + report.bucket_id).then(result => {
            setBucket(result)
        }).catch(err => console.log(err))
    }, [report])

    const limitRegex = new RegExp(/^-?\d+$/)
    useEffect(() => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        if (limitRegex.test(tmpLimit) && Number(tmpLimit) >= 0) {
            tmp.limit = Number(tmpLimit)
            setTmpReport(tmp)
        }

    }, [tmpLimit])

    const onDeleteTemplate = () => {
        setShowDeleteTemplateWarning(true)
    }

    const onConfirmDeleteTemplate = () => {
        del(`cloud/${cloudId}/templates/report/${reportId}`).then(() => {
            navigate(`/templates/${cloudId}/reports`)
        }).catch(e => console.log(e))
        setShowDeleteTemplateWarning(false)
    }

    const onSaveName = () => {
        if (tmpName === "" || tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.name = tmpName
        tmp.description = tmpDesc
        setTmpReport(tmp)
        setShowEditName(false)
    }

    const onEditName = () => {
        setTmpName(tmpReport?.name ?? "Name")
        setTmpDesc(tmpReport?.description ?? "")
        setShowEditName(true)
    }

    const disableSave = () => {
        if (tmpReport === undefined) return true
        return compareObjects(tmpReport, report) || (!limitRegex.test(tmpLimit) && tmpReport.limit > -1) || (tmpReport.limit > -1 && (tmpReport.sort_direction === "" || tmpReport.sort_column === ""))
    }

    const onSave = () => {
        if (tmpReport === undefined) return
        setSaving(true)

        put(`cloud/${cloudId}/templates/report`, tmpReport).then(result => {
            setReport(result)
            setSaving(false)
        }).catch(e => console.log(e))
    }

    const onUndo = () => {
        setTmpReport(structuredClone(report))
        setTmpLimit(report?.limit !== undefined && report.limit > -1 ? `${report.limit}` : "")
    }

    const selectColumns = (cols: string[]) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.columns.push(...cols)
        if (tmp.shown_columns.length < 8) {
            let c = cols.slice(0, 8 - tmp.shown_columns.length)
            tmp.shown_columns.push(...c)
        }
        if (tmp.limit > -1 && tmp.sort_column === "" && tmp.columns.length > 0) {
            tmp.sort_column = tmp.columns[0]
        }
        setTmpReport(tmp)
    }

    const deselectColumns = (cols: string[]) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.columns = tmp.columns.filter(a => !cols.some(b => a === b))
        tmp.shown_columns = tmp.shown_columns.filter(a => !cols.some(b => a === b))
        tmp.kpis = tmp.kpis.filter(a => !cols.some(b => a.column === b))
        tmp.fixed_slicers = tmp.fixed_slicers.filter(a => !cols.some(b => a === b))
        if (cols.some(c => c === tmp.sort_column)){
            tmp.sort_column = tmp.columns.length > 0 ? tmp.columns[0] : ""
        }
        setTmpReport(tmp)
    }

    const toggleLimit = () => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        if (tmp.limit === -1) {
            setTmpLimit("10")
            tmp.limit = 10
            tmp.sort_column = tmp.columns.length > 0 ? tmp.columns[0] : ""
            tmp.sort_direction = "desc"
        } else {
            tmp.limit = -1
            setTmpLimit("Limit")
            tmp.sort_column = ""
            tmp.sort_direction = ""
        }
        setTmpReport(tmp)
    }

    const onSortColumnChange = (value: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.sort_column = value
        setTmpReport(tmp)
    }

    const onSortDirectionChange = (value: SortDirection) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.sort_direction = value
        setTmpReport(tmp)
    }

    const onDefaultGroupChange = (value: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.default_group = value
        setTmpReport(tmp)
    }


    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 onClick={onEditName} className="cursor-pointer text-gray-500 text-lg font-semibold">Insight Setup - {tmpReport?.name ?? "Loading"}</h1></div>
            <Menu as="div" className="relative inline-block text-left mx-4">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        Options
                        <HiChevronDown
                            className="ml-2 -mr-1 h-5 w-5 text-orange-200 hover:text-orange-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-orange-100 text-black' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={onEditName}
                                    >
                                        Edit name
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-red-600 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={onDeleteTemplate}
                                    >
                                        Delete
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
        <div className={`mx-4 flex flex-col gap-4 ${saving ? 'pointer-events-none opacity-50':''}`}>
            {
                tmpReport === undefined || bucket === undefined ? <h1>Loading...</h1>
                    : <>
                        <div className='flex gap-2 justify-between'>
                            <div className='flex gap-2'>
                                <Button onClick={onSave} disabled={disableSave()} icon={<FaSave />} text='Save' color='primary' className='w-fit' />
                                <Button onClick={onUndo} disabled={disableSave()} icon={<FaUndo />} text='Undo' color='secondary' className='w-fit' />
                            </div>
                        </div>
                        <TabBar style={{marginBottom: 0}} setTab={i => setTab(i)} tabs={["Column setup", "View setup", "Slicer setup", "KPI setup", "Matrix setup"]} selected={tab} />
                        {/* Column setup */}
                        {tab === 0 && <>
                            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Column setup</h2>
                            <ReportTemplateColumnSelect
                                available={bucket.columns.map(c => c.name)}
                                selected={tmpReport.columns}
                                selectColumns={selectColumns}
                                deselectColumns={deselectColumns}
                                bucket={bucket}/>

                            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Column visibility</h2>
                            <ReportTemplateColumnShowHide tmpReport={tmpReport} setTmpReport={setTmpReport} />
                            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Additional settings</h2>
                            <div className='flex items-center gap-2'>
                                <CustomCheckbox value={tmpReport.limit !== -1} text='Limit' onChange={toggleLimit} />
                                <CustomInput value={tmpLimit} onChange={(e) => setTmpLimit(e.target.value)} pattern="\d*" disabled={tmpReport.limit === -1} error={!limitRegex.test(tmpLimit) && tmpReport.limit > -1} placeholder='Limit' />
                                <SearchableSelect
                                    value={tmpReport.sort_column}
                                    options={tmpReport.columns.map(c => ({label: c, value: c}))}
                                    onChange={onSortColumnChange}            
                                    direction='up'
                                    disabled={tmpReport.limit === -1}
                                    className='w-[200px]'
                                    placeholder='Sort column'
                                    />
                                <SearchableSelect
                                    value={tmpReport.sort_direction}
                                    options={[{label: "Top", value: "desc"}, {label: "Bottom", value: "asc"}]}
                                    onChange={onSortDirectionChange}            
                                    direction='up'
                                    disabled={tmpReport.limit === -1}
                                    className='w-[200px]'
                                    placeholder='Sort direction'
                                    />
                            </div>
                            <div>
                                <label>Default group name</label>
                                <InputWithSuggestions placeholder='Default group' value={tmpReport.default_group ?? ''} onChange={v => onDefaultGroupChange(v)} options={defaultGroups ?? ["Loading..."]} direction='down' />
                            </div>
                            <div className='h-64' />
                        </>
                        }
                        {/* View setup */}
                        {tab === 1 && <>
                            <ReportTemplateViewSettings tmpReport={tmpReport} setTmpReport={setTmpReport} bucket={bucket} />
                        </>}
                        {/* Slicer setup */}
                        {tab === 2 && <>
                            <ReportTemplateSlicerSetup tmpReport={tmpReport} setTmpReport={setTmpReport} bucket={bucket} />
                        </>}
                        {/* Kpi view setup */}
                        {tab === 3 && <>
                            <ReportTemplateKpiConfig tmpReport={tmpReport} setTmpReport={setTmpReport} bucket={bucket} />
                        </>}
                        {/* Matrix view setup */}
                        {tab === 4 && <>
                            <ReportTemplateMatrixConfig tmpReport={tmpReport} setTmpReport={setTmpReport} bucket={bucket} />
                        </>}
                    </>
            }
        </div>
        <WarningModal
            isOpen={showDeleteTemplateWarning}
            title='Delete bucket template'
            actionText='Delete'
            closeText='Cancel'
            onClose={() => setShowDeleteTemplateWarning(false)}
            onAction={onConfirmDeleteTemplate}
        >
            Are you sure you want to delete this template? <span className='text-red-600 font-bold'>This is permanent and cannot be undone.</span>
        </WarningModal>

        <Modal isOpen={showEditName} 
            onClose={() => {setShowEditName(false); setTmpName(tmpReport?.name ?? "")}} 
            onAction={onSaveName} 
            title='Edit name' 
            actionText='Save' 
            closeText='Cancel'
            size='small'
            disableAction={tmpName === ""}>
            <div className='mt-3 flex flex-col gap-2'>
                <CustomInput label='Name' id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
                <label htmlFor='new-desc' className='text-gray-600 text-xs mt-3'>Description</label>
                <textarea id='new-desc' className='text-sm' value={tmpDesc} onChange={e => setTmpDesc(e.target.value)} />
            </div>
        </Modal>
    </div>
}

export default TemplateReportEdit
