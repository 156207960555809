/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { FaTh, FaTimes } from "react-icons/fa";
import { getStateReducer } from "../../helpers/general_helpers";
import { compareObjects } from "../../helpers/type_helpers";
import { ActionTemplate, BucketColumnType, BucketTemplate, CompareType, getCompareTypes, ReportFilter, ReportTemplate } from "../../types/template_types";
import Button from "../Button";
import CustomInput from "../CustomInput";
import { Modal } from "../Modals";
import SearchableSelect from "../SearchableSelect";
import ShowIf from "../ShowIf";

interface ReportTemplateSlicerEditorProps {
    bucket: BucketTemplate,
    tmpReport: ReportTemplate | ActionTemplate,
    slicer?: ReportFilter,
    slicers: ReportFilter[],
    saveSlicer: (slicer: ReportFilter) => void,
    show: boolean,
    onClose: () => void,
}

const ReportTemplateSlicerEditor = ({bucket, slicer, saveSlicer, tmpReport, ...props}: ReportTemplateSlicerEditorProps) => {
    const [tmpSlicer, updateTmpSlicer] = useReducer(getStateReducer<ReportFilter>(), {column: "", compare_type: CompareType.EQ, target_values: [], is_column: false})
    const bucketColumn = useMemo(() => bucket.columns.find(x => x.name === tmpSlicer.column) ?? {name: "Not found", type: BucketColumnType.Text, values: []}, [tmpSlicer.column])

    const bucketTypes = useMemo(() => {
        let map = new Map<string, BucketColumnType>()
        bucket.columns.forEach(c => map.set(c.name, c.type))
        return map
    }, [bucket])

    const [textInput, setTextInput] = useState("")

    useEffect(() => {
        if (slicer === undefined) {
            if (tmpReport.columns.length === 0) {
                updateTmpSlicer({column: "", compare_type: CompareType.EQ, target_values: []})
            } else {
                let col = tmpReport.columns[0]
                updateTmpSlicer({column: col, compare_type: CompareType.EQ, target_values: []})
            }
        } else {
            updateTmpSlicer(structuredClone(slicer))
        }
    }, [slicer])

    useEffect(() => {
        addEventListener('keydown', handleKey, false)
        return () => {
            removeEventListener('keydown', handleKey, false)
        }
    }, [])


    // Makes sure that pressing escape does not close the modal
    const handleKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && props.show) {
            event.preventDefault()
        }
    }

    const disableSave = () => {
        return compareObjects(slicer, tmpSlicer)
    }

    const onColumnChange = (column: string) => {
        let existingSlicer = props.slicers.find(x => x.column === column)
        if (existingSlicer !== undefined) {
            updateTmpSlicer(existingSlicer)
        } else {
            updateTmpSlicer({column: column, compare_type: CompareType.EQ, target_values: [], is_column: false})
        }
    }

    const onCompareChange = (compType: CompareType) => {
        updateTmpSlicer({compare_type: compType})
    }

    const onSave = () => {
        saveSlicer(tmpSlicer)
    }

    const onNumberChange = (value: string, position = 0) => {
        let v = value.replace(/[^0-9.-]/, '')
        let arr = structuredClone(tmpSlicer.target_values)
        arr[position] = v
        updateTmpSlicer({target_values : arr})
    }

    const addValue = (value: string) => {
        updateTmpSlicer(current => ({target_values: [...current.target_values, value]}))
    }

    const addTextValue = () => {
        if (textInput === "") return
        let values = textInput.split(',').map(s => s.trim()).filter(s => s !== "")
        updateTmpSlicer(current => ({target_values: [...current.target_values, ...values]}))
        setTextInput("")
    }

    const removeValue = (value: string) => {
        updateTmpSlicer(current => ({target_values: current.target_values.filter(v => v !== value)}))
    }

    const selectAllValues = () => {
        let notSelected = categoryValues.filter(x => !tmpSlicer.target_values.includes(x))
        updateTmpSlicer(current => ({target_values: [...current.target_values, ...notSelected]}))
    }

    const onDateChange = (value: string, position = 0) => {
        let values = structuredClone(tmpSlicer.target_values)
        values[position] = value
        updateTmpSlicer({target_values: values})
    }

    const categoryValues = bucket.categorization_name === tmpSlicer.column ? 
        bucket.categorization.map(x => x.name)
        : 
        bucketColumn.values

    const shownCategoryValues = categoryValues.filter(x => !tmpSlicer.target_values.includes(x)).filter(x => x.toLowerCase().includes(textInput.toLowerCase()))
    const columns = tmpReport.columns.sort()
    const columnsOfSameType = columns.filter(s => bucketTypes.get(s) === bucketColumn.type)

    return <>
        {tmpSlicer === undefined ? null
            :
            <Modal onKeyDown={handleKey} disableAction={disableSave()} isOpen={props.show} onClose={props.onClose} actionText="Save" onAction={onSave} closeText="Cancel" title="Edit slicer">
                <div className="flex flex-col gap-2 min-h-[500px]">
                    <div className="flex gap-2">
                        <SearchableSelect 
                            options={columns.map(v => {return {value: v, label: v, icon: v === bucket.categorization_name ? <FaTh className="w-[14px] h-[14px]" /> : null}})}
                            onChange={onColumnChange}
                            value={tmpSlicer.column}
                            className="w-[60%]"
                        />

                        <SearchableSelect 
                            options={getCompareTypes(bucketColumn.type, tmpSlicer.is_column)}
                            onChange={onCompareChange}
                            value={tmpSlicer.compare_type}
                            className="w-[40%]"
                        />
                    </div>
                    <div className="flex flex-col gap-2 h-full flex-grow justify-between">
                        <ShowIf if={!tmpSlicer.is_column}>
                            <ShowIf if={bucketColumn.type === BucketColumnType.Decimal}>
                                <div>
                                    Number
                                    <CustomInput value={tmpSlicer.target_values[0] ?? ""} type={"text"} onChange={(e) => onNumberChange(e.target.value)} />
                                    <ShowIf if={(tmpSlicer.compare_type === CompareType.BETWEEN || tmpSlicer.compare_type === CompareType.NBETWEEN)}>
                                        <span>And</span>
                                        <CustomInput value={tmpSlicer.target_values[1] ?? ""} type={"text"} onChange={(e) => onNumberChange(e.target.value, 1)} />
                                    </ShowIf>
                                    <ShowIf if={!(tmpSlicer.compare_type === CompareType.BETWEEN || tmpSlicer.compare_type === CompareType.NBETWEEN)}>
                                        <div className="flex justify-end mt-2">
                                            <Button color="primary" onClick={() => updateTmpSlicer({is_column: true, compare_type: CompareType.EQ, target_values: [columnsOfSameType.at(0)]})}>Slice on column</Button>
                                        </div>
                                    </ShowIf>
                                </div>
                            </ShowIf>
                            <ShowIf if={bucketColumn.type === BucketColumnType.Text || bucketColumn.type === BucketColumnType.Categorization || bucketColumn.type === BucketColumnType.ID}>
                                <div>
                                    Text
                                    <ShowIf if={tmpSlicer.column !== bucket.categorization_name}>
                                        <div className="flex justify-center items-center gap-2">
                                            <input type={"text"} value={textInput} onChange={(e) => setTextInput(e.target.value)} className="text-sm py-1.5 block rounded-md shadow-sm border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 w-full" />
                                            <Button color="primary" text="Add" onClick={addTextValue} />
                                        </div>
                                    </ShowIf>

                                    <ShowIf if={categoryValues.length > 0}>
                                        <div className="flex gap-2">
                                            <span>Pre-defined values</span>
                                            <button onClick={selectAllValues} className="text-xs px-2 py-1 bg-white hover:bg-neutral-200 border border-neutral-300 rounded flex items-center">Select all</button>
                                        </div>
                                        <div className="scroll-shadows flex flex-col w-full max-h-[300px] min-h-[100px] overflow-y-scroll border rounded mx-auto text-sm">
                                            {
                                                shownCategoryValues.map((v, i) => {
                                                    return <button key={i} onClick={() => addValue(v)} className={`flex p-2 items-center border-b hover:bg-gray-100`}>
                                                        {v}
                                                    </button>
                                                })
                                            }
                                            <ShowIf if={shownCategoryValues.length === 0}>
                                                <i className="p-2">No values found</i>
                                            </ShowIf>
                                        </div>
                                    </ShowIf>
                                </div>
                                <div className="mt-2 border-t pt-2">
                                    <div className="flex items-center justify-between">
                                        <span className="">Included values: </span>
                                        <button onClick={() => updateTmpSlicer({target_values: []})} className="text-xs px-2 py-1 bg-red-600 hover:bg-red-500 text-white rounded flex items-center">Clear</button>
                                    </div>
                                    <div className="flex mt-1 gap-1 flex-wrap">
                                        {
                                            tmpSlicer.target_values.map((v, i) => {
                                                return <div key={i} className="text-xs px-2 py-1 bg-neutral-600 text-white rounded flex items-center gap-1">
                                                    {v}
                                                    <button onClick={() => removeValue(v)}><FaTimes /></button>
                                                </div>
                                            })
                                        }
                                        <ShowIf if={tmpSlicer.target_values.length === 0}>
                                            <i>None selected</i>
                                        </ShowIf>
                                    </div>
                                </div>
                            </ShowIf>
                            <ShowIf if={bucketColumn.type === BucketColumnType.Date}>
                                <div>
                                    Date
                                    <input type={"date"} onChange={e => onDateChange(e.target.value)} value={tmpSlicer.target_values[0] ?? ""} />
                                    <ShowIf if={(tmpSlicer.compare_type === CompareType.BETWEEN || tmpSlicer.compare_type === CompareType.NBETWEEN)}>
                                        <span>And</span>
                                        <input type={"date"} onChange={e => onDateChange(e.target.value, 1)} value={tmpSlicer.target_values[1] ?? ""} />
                                    </ShowIf>
                                </div>
                            </ShowIf>
                        </ShowIf>
                        <ShowIf if={tmpSlicer.is_column}>
                            <div>
                            Number
                                <SearchableSelect 
                                    options={columnsOfSameType.map(s => ({label: s, value: s}))}
                                    onChange={(v) => updateTmpSlicer({target_values: [v]})}
                                    value={tmpSlicer.target_values.at(0) ?? columnsOfSameType.at(0)}
                                />
                                <div className="flex justify-end mt-2">
                                    <Button color="primary" onClick={() => updateTmpSlicer({is_column: false, compare_type: CompareType.EQ, target_values: []})}>Slice on value</Button>
                                </div>
                            </div>
                        </ShowIf>
                    </div>

                </div>
            </Modal>}
    </>
}

export default ReportTemplateSlicerEditor
