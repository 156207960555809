import { AiOutlineSearch } from "react-icons/ai";
import { TableHead, TableHeadRow, TableHeadCell, TableBody, TableBodyCell } from "../Table";
import { CloudOrgInfo, CloudUser, OrganizationSubscriptionSettings } from "../../types/cloud_types";
import EditUserModal from "./EditUserModal";
import { useEffect, useState } from "react";
import { get, post, put } from "../../helpers/Requests";
import CustomCheckbox from "../Buttons/CustomCheckbox";
import EditMultiple, { EditMultipleProps } from "./EditMultiple";
import MultiSelectBar, { MultiSelectButtonType, MultiSelectOption } from "../MultiSelectBar";
import SimpleToast, { SimpleToastProps } from "../Toasts/SimpleToast";
import { Modal, WarningModal } from "../Modals";
import { WarningModalProps } from "../Modals/WarningModal";
import Button from "../Button";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { FaUndo } from "react-icons/fa";
import React from "react";
import { useDispatch } from "../../contexts/StoreContext";
import { getDateString } from "../../helpers/general_helpers";
import AdminPanelSettingsModal from "./AdminPanelSettingsModal";
import { HiOutlineCog } from "react-icons/hi";

type EditMultipleConfig = Omit<EditMultipleProps, "shown" | "closeModalHandler" | "savePermissions">
type UserOverviewProps = {
    org: CloudOrgInfo,
}

enum sortKey {
    FIRSTNAME = "firstname",
    EMAIL = "email",
    DEPARTMENT = "department",
    PHONE_NUMBER = "phone_number",
    JOB_TITLE = "job_title",
    LANGUAGE = "language",
    LAST_LOGIN = "last_login",
    LOCATION = "location",
    DEFAULT = "default"
}

type ISortConfig = {
    key: sortKey,
    direction: "asc" | "desc"
}
export default function UserOverview({ org, }: UserOverviewProps) {
    const [searchText, setSearchText] = useState("")
    const [filteredUsers, setFilteredUsers] = useState<CloudUser[]>(org.users ?? [])
    const searchProperties = ["firstname", "lastname", "email", "job_title", "department", "language"]
    const [userToEdit, setUserToEdit] = useState<number>(-1)
    const [disabledUsers, setDisabledUsers] = useState(!!org.users ? org.users.filter((u) => u.deleted) : [])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showAdminPanelSettings, setShowAdminPanelSettings] = useState(false);
    const [checkedRows, setCheckedRows] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState(false)
    const [orgItems, setOrgItems] = useState<any>(null)
    const dispatch = useDispatch()
    const [showMultiEditModal, setMultiShowEditModal] = useState(false)
    const [multiSelectDialogProps, setMultiSelectDialogProps] = useState<EditMultipleConfig>({ title: "", selected: [], type: "user" })
    const [sortConfig, setSortConfig] = useState<ISortConfig>({
        key: sortKey.DEFAULT,
        direction: "asc"
    })
    const [deletedUsersModalisOpen, setDeletedUsersModalIsOpen] = useState(false)
    const [toastConfig, setToastConfig] = useState<SimpleToastProps>({
        type: "success",
        text: "", 
        show: false,
    })
    const [locationFieldSettings, setLocationFieldSettings] = useState({
        location_field_enabled: false,
        location_field_mandatory: false
    })
    const [locations, setLocations] = useState<string[]>([]);

    const [warningConfig, setWarningConfig] = useState<WarningModalProps>({
        isOpen: false,
        onClose: () => { },
        onAction: () => { },
        title: "",
        actionText: "",
        closeText: "",
    })
    const [orgSubscriptionSettings, setOrgSubscriptionSettings] = useState<OrganizationSubscriptionSettings>()


    useEffect(() => {
        if (org.users === undefined) {
            get(`cloud/${org.server?.id}/organizations/${org.id}/users`)
                .then((users) => {
                    let newOrg = { ...org };
                    newOrg.users = users;
                    dispatch(({
                        type: 'change_org',
                        id: newOrg.id,
                        org: newOrg
                    }))
                })
        }
        if(!orgSubscriptionSettings){
            get(`cloud/${org.server?.id}/organizations/${org.id}/subscription_settings`)
            .then(settings => {
                console.log(settings);
                setOrgSubscriptionSettings(settings)
            }).catch(err => console.log(err))
        }
    }, [])

    let checkedUserText = checkedRows.length > 1 ? "users" : "user"
    const multiSelectOptions: MultiSelectOption[] = [
        {
            label: (
                <span>
                    Set permissions for <strong>{checkedRows.length}</strong> {checkedUserText}
                </span>
            ),
            onClick: (checkedRows: number[] | undefined) => multiSelectDialogHandler("permissions", checkedRows!),
        },
        {
            label: (
                <span>
                    Send welcome mail to <strong>{checkedRows.length}</strong> {checkedUserText}
                </span>
            ),
            onClick: (checkedRows: number[] | undefined) => sendMultipleMails(checkedRows!),
        },
        {
            label: (
                <span>
                    Disable <strong>{checkedRows.length} </strong> {checkedUserText}
                </span>
            ),
            type: MultiSelectButtonType.WARNING,
            onClick: (ids: number[] | undefined) => {
                disableMultipleUsersHandler(ids!)

            },
        },
    ]

    useEffect(() => {
        if (org.use_access_control && !orgItems) {
            get(`cloud/${org.server?.id}/organizations/${org.id}/items`)
                .then(setOrgItems)
                .catch(err => {
                    console.log(err)
                })
        }
        if (org.location_field_enabled){
            get(`cloud/${org.server?.id}/organizations/${org.id}/locations`)
                .then(setLocations)
                .catch(err => {
                    console.log("locations", err)
                })
        }
    }, [org.location_field_enabled, org.use_access_control])

    useEffect(() => {
        setShowEditModal(false)
        setCheckedRows([])

    }, [disabledUsers.length])

    async function sendMultipleMails(checked: number[]) {
        checked.forEach(async (c) => {
            // send multiple mails
            const url = `cloud/${org.server?.id}/users/${c}/mail/welcome`

            await post(url, null).then((res) => {
                if (!!res) {

                    const mail = {

                        id: -1,
                        user_id: c,
                        mail_type: "welcome_mail",
                        send_time: Date.now()
                    }


                    const users = !!org.users ? [...org.users] : [];
                    const index = users.findIndex(u => newUser?.id === u.id)
                    const newUser: any = { ...users[index] }
                    newUser.last_welcome_mail = mail;

                    users.splice(index, 1, newUser)
                    const o = { ...org }
                    o.users = users;

                    dispatch({ type: "change_org", id: o.id, org: o })

                    const tConfig = { ...toastConfig }
                    tConfig.text = "Welcome mails sent successfully"
                    tConfig.show = true;
                    tConfig.type = "success"
                    setToastConfig(tConfig)
                    setTimeout(() => {
                        const c = { ...tConfig }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                } else {

                    const users = !!org.users ? [...org.users] : [];
                    const index = users.findIndex(u => c === u.id)
                    const tConfig = { ...toastConfig }
                    tConfig.text = `Something went wrong while sending welcome mail to ${users[index].email}`
                    tConfig.show = true;
                    tConfig.type = "danger"

                    setToastConfig(tConfig)

                    setTimeout(() => {
                        const c = { ...tConfig }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                }
            })
        })
    }

    async function enableUserHandler(id: number) {
        setUserToEdit(id)
        const user = disabledUsers.find((u) => u.id === id)
        if (user) {
            user.deleted = false;
            let payload = user
            const body: any = { ...payload }

            await put(`/cloud/${org.server?.id}/users/${user.id}`, body).then((res) => {
                if (!!res) {
                    const users = !!org.users ? [...org.users] : [];
                    const index = users.findIndex(u => user.id === u.id)
                    users.splice(index, 1, user)
                    const o = { ...org }
                    o.users = users;

                    dispatch({ type: "change_org", id: o.id, org: o })
                    setFilteredUsers(!!org.users ? org.users : [])
                }
            })
        }
    }

    const handleSelectAll = (checked: any) => {

        if (checked && org.users) {
            const ids = org.users.reduce((result: number[], u) => { if (u.id) { result.push(u.id) } return result }, [])
            setCheckedRows(ids)
            setSelectAll(true)

        } else {
            setCheckedRows([])
            setSelectAll(false)
        }
    }

    async function disableMultiUsers() {
        const payload = {
            user_ids: checkedRows,
            organization_id: org.id,
        }
        await put(`/cloud/${org.server?.id}/users/disable`, payload).then((res) => {
            if (!!res) {
                const deleted = res.map((r: CloudUser) => r.id)

                const updatedUsers = org.users?.map(u => {
                    if (deleted.includes(u.id)) {
                        u.deleted = true;
                    }

                    return u;
                })

                const updatedOrg = { ...org }
                updatedOrg.users = updatedUsers;
                dispatch({ type: "change_org", org: updatedOrg, id: updatedOrg.id })
                const config = { ...warningConfig }
                config.isOpen = false;
                setWarningConfig(config)
                const tConfig = { ...toastConfig }
                tConfig.text = "Users disabled successfully"
                tConfig.type = "success"
                tConfig.show = true;
                setToastConfig(tConfig)
                setTimeout(() => {
                    const c = { ...tConfig }
                    c.show = false;
                    setToastConfig(c)
                }, 5000)

            }
        })

        setSelectAll(false)
        setCheckedRows([])
    }

    function userClickedHandler(userID: number) {
        setCheckedRows([])
        setSelectAll(false)
        setUserToEdit(userID)
        setShowEditModal(true)
    }

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value)
        orgSearch(event.target.value)
    }

    const orgSearch = (query: string) => {

        if (!query) {

            const users = org.users?.filter(u => !u.deleted) ?? []
            setFilteredUsers(users)
        }

        if(org.location_field_enabled && !searchProperties.includes("location")){
            searchProperties.push("location")
        }

        setTimeout(() => {
            const queries = query.split(" ").map(query => query.toLowerCase())
            const filtered = org.users?.filter((user) => {

                if (!user.deleted) {
                    const values = Object.entries(user).filter(entry => typeof entry[1] === "string" && searchProperties.includes(entry[0])).map(entry => entry[1].toLowerCase())

                    return queries.every(query => {
                        return values.some(entry => {
                            return entry.includes(query)
                        })
                    })
                }
                return false;
            })

            if (filtered) {
                setFilteredUsers(filtered)
            }
        }, 500)
    }
    
    function disableMultipleUsersHandler(uIDs: number[]) {
        const config = { ...warningConfig }
        config.title = `Are you sure you want to disable ${checkedRows.length} users`;
        config.actionText = "Disable"
        config.closeText = "Cancel"
        config.isOpen = true;
        config.onClose = () => { config.isOpen = false; setWarningConfig({...config}) }
        config.onAction = () => disableMultiUsers();
        setWarningConfig({...config})
    }

    async function saveMultiplePermissionsHandler(perms: string[], users: number[]) {

        const payload = {
            "user_ids": users,
            "permissions": perms,
            "organization_id": org.id
        }
        await put(`/cloud/${org.server?.id}/users/permissions`, payload).then((res) => {
            if (!!res) {
                const updatedOrg = { ...org }
                for (const user of res) {
                    const index = org.users?.findIndex((u) => u.id === user.id)
                    const updatedUser = org.users && index ? { ...org.users[index] } : null
                    if (updatedUser) updatedUser.permissions = user.permissions
                    if (index && updatedUser) updatedOrg.users?.splice(index, 1, updatedUser)
                }
                dispatch({ type: "change_org", org: updatedOrg, id: org.id })
                const config = { ...toastConfig }
                config.text = "Permissions updated successfully"
                config.show = true;
                config.type = "success"

                setToastConfig(config)
                setTimeout(() => {
                    const c = { ...config }
                    c.show = false;
                    setToastConfig(c)
                }, 2000)
                setMultiShowEditModal(false)
            }

        })
    }

    useEffect(() => {
        if (userToEdit === -1) {
            closeHandler()
        }
        const oUsers = org.users ? [...org.users] : []
        const users = oUsers.filter(u => !u.deleted)
        const disabled = oUsers.filter(u => u.deleted)
        setDisabledUsers(disabled)
        setFilteredUsers(users)

    }, [org.users])

    useEffect(() => {
        const lfs = {
            location_field_enabled: org.location_field_enabled ?? false,
            location_field_mandatory: org.location_field_mandatory ?? false
        }
        setLocationFieldSettings({...lfs});
    }, [org.location_field_enabled, org.location_field_mandatory])

    function requestSort(key: sortKey) {
        let direction: ISortConfig["direction"] = "asc"
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc"
        }
        setSortConfig({ key: key, direction: direction })
    }

    const getSortDirectionIcon = (key: string) => {
        if (sortConfig && sortConfig.key === key) {
            return sortConfig.direction === "asc" ? <BiSortUp></BiSortUp> : <BiSortDown></BiSortDown>
        }
        return ""
    }

    function closeHandler() {
        setShowEditModal(false)
    }

    const handleCheck = (event: any, user: CloudUser) => {
        if (event && checkedRows.length > -1 && user && user.id) {
            setCheckedRows([...checkedRows, user.id])
        } else {
            setCheckedRows(checkedRows.filter((id) => id !== user.id))
        }
    }

    const multiSelectDialogHandler = (option: "permissions" | "user", values: number[]) => {
        const selectedUsers = org.users?.filter((user: CloudUser) => { return user.id && values.includes(user.id) })
        setMultiShowEditModal(true)
        setMultiSelectDialogProps({ title: `Edit ${option} for ${selectedUsers?.length} ${selectedUsers?.length === 1 ? "user" : "users"} `, type: option, selected: selectedUsers ? selectedUsers : [] })
    }

    return (
        <>
            <WarningModal {...warningConfig}></WarningModal>
            <EditMultiple savePermissions={(perms: string[], users: number[]) => saveMultiplePermissionsHandler(perms, users)} type={multiSelectDialogProps.type} selected={multiSelectDialogProps.selected} title={multiSelectDialogProps.title} closeModalHandler={() => null} shown={showMultiEditModal}></EditMultiple>
            <SimpleToast type={toastConfig.type} text={toastConfig.text} show={toastConfig.show}></SimpleToast>
            <header className="flex justify-between mb-5">
                <h1 className="text-4xl bg-transparent flex items-center gap-2">{org.name}
                    <HiOutlineCog className="cursor-pointer hover:text-gray-400 text-2xl" onClick={() => setShowAdminPanelSettings(true)}/>
                </h1>
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="pb-4 bg-transparent ">
                    Showing {filteredUsers.length} of {org.users?.filter(u => !u.deleted)?.length}{" "}
                    <div className="relative mt-1">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <AiOutlineSearch size="1.1em" color="gray" />
                        </div>
                        <input type="text" id="table-search" value={searchText} onChange={handleSearchTextChange} className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for users" />
                    </div>
                </div>
            </header>
            <div className=" flex flex-col">
                <div className=" ">
                    <div className="overflow-x-auto w-full rounded-lg border">

                        <table className="min-w-full divide-y divide-gray-200"   >
                            <TableHead className="text-left text-s whitespace-nowrap text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 " >
                                <TableHeadRow>
                                    <TableHeadCell className="normal-case text-[14px]"><CustomCheckbox value={selectAll} onChange={(event: any) => handleSelectAll(event)}></CustomCheckbox></TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px] h-full" onClick={() => requestSort(sortKey.FIRSTNAME)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Name</span>
                                            {getSortDirectionIcon(sortKey.FIRSTNAME)}
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.EMAIL)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Email</span>
                                            {getSortDirectionIcon(sortKey.EMAIL)}
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.PHONE_NUMBER)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Phone</span>
                                            {getSortDirectionIcon(sortKey.PHONE_NUMBER)}
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.DEPARTMENT)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Department</span>
                                            {getSortDirectionIcon(sortKey.DEPARTMENT)}
                                        </div>
                                    </TableHeadCell>
                                    {org.location_field_enabled && 
                                        <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.LOCATION)}>
                                            <div className="flex">
                                                <span className="mr-1 inline">Location</span>
                                                {getSortDirectionIcon(sortKey.LOCATION)}
                                            </div>
                                        </TableHeadCell>
                                    }
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.LANGUAGE)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Language</span>
                                            {getSortDirectionIcon(sortKey.LANGUAGE)}
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.JOB_TITLE)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Job Title</span>
                                            {getSortDirectionIcon(sortKey.JOB_TITLE)}
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell className="normal-case text-[14px]" onClick={() => requestSort(sortKey.LAST_LOGIN)}>
                                        <div className="flex">
                                            <span className="mr-1 inline">Last Login</span>
                                            {getSortDirectionIcon(sortKey.LAST_LOGIN)}
                                        </div>
                                    </TableHeadCell>
                                </TableHeadRow>
                            </TableHead>
                            <TableBody >
                                {
                                    !!filteredUsers ?
                                        filteredUsers?.map(user =>
                                            <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer hover:bg-gray-50 dark:text-gray-50 dark:hover:bg-gray-900" >
                                                <TableBodyCell className=" whitespace-nowrap"><CustomCheckbox value={user && user.id ? checkedRows.includes(user.id) : false} onChange={(event: any) => handleCheck(event, user)} ></CustomCheckbox></TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap overflow-hidden text-ellipsis max-w-[300px]" title={user.firstname + " " + user.lastname} onClick={() => { if (!!user.id) userClickedHandler(user.id) }} >{user.firstname} {user.lastname}</TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.email}</TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.phone_number ? user.phone_number : "Not set"}</TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.department}</TableBodyCell>
                                                {org.location_field_enabled && 
                                                    <TableBodyCell className=" whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]" title={user.location ?? "-"} onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>
                                                        {user.location ? user.location : "-"}
                                                    </TableBodyCell>
                                                }
                                                <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.language}</TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.job_title}</TableBodyCell>
                                                <TableBodyCell className=" whitespace-nowrap " onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{getDateString(user.last_login)}</TableBodyCell>
                                            </tr>
                                        )
                                        :
                                        <>no users to show</>
                                }
                            </TableBody>
                        </table>
                    </div>
                </div>
                <Modal isOpen={deletedUsersModalisOpen} size={"large"} title="Disabled users" closeText="Close" onClose={() => setDeletedUsersModalIsOpen(false)}>
                    <table className="min-w-full divide-y divide-gray-200"   >
                        <TableHead className="text-left text-s whitespace-nowrap text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 " >
                            <TableHeadRow>
                                <TableHeadCell className="normal-case text-[14px]">Name</TableHeadCell>
                                <TableHeadCell className="normal-case text-[14px]">Email</TableHeadCell>
                                <TableHeadCell className="normal-case text-[14px]"></TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody >
                            {
                                !!disabledUsers ?
                                    disabledUsers?.map(user =>
                                        <tr key={user.id} className="bg-white border-b text-sm dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer hover:bg-gray-50 dark:text-gray-50 dark:hover:bg-gray-900" >

                                            <TableBodyCell className=" whitespace-nowrap truncate max-w-xs" onClick={() => { if (!!user.id) userClickedHandler(user.id) }} >{user.firstname} {user.lastname} </TableBodyCell>
                                            <TableBodyCell className=" whitespace-nowrap" onClick={() => { if (!!user.id) userClickedHandler(user.id) }}>{user.email}</TableBodyCell>

                                            <TableBodyCell className=" whitespace-nowrap " onClick={() => { if (!!user.id) enableUserHandler(user.id) }}><Button color="success" className="text-xs" icon={<FaUndo className="text-xs font-semibold" aria-hidden="true"></FaUndo>} text={` Enable`} /></TableBodyCell>
                                        </tr>
                                    )
                                    :
                                    <>no users to show</>
                            }
                        </TableBody>
                    </table>
                </Modal>
                <MultiSelectBar selected={checkedRows} options={multiSelectOptions} type="user" onDeselectClick={() => { setCheckedRows([]); setSelectAll(false) }}></MultiSelectBar>
                <Button
                    color="default"
                    className="self-end mt-2"
                    onClick={(e) => {
                        setDeletedUsersModalIsOpen(true)
                    }}

                    text={`Disabled users (${disabledUsers.length})`}
                />
            </div>
            {showEditModal && !!org?.users && 
                <EditUserModal 
                    org={org} 
                    selectableItems={orgItems} 
                    isOpen={true} 
                    onClose={() => closeHandler()} 
                    user={org.users.find((u) => userToEdit === u.id) ?? null} 
                    server={org?.server?.id + "" ?? ""}
                    locationFieldSettings={locationFieldSettings}
                    locations={locations}
                    setLocations={setLocations} 
                />
            }
            {showAdminPanelSettings && !!orgSubscriptionSettings &&
                <AdminPanelSettingsModal 
                    orgSubscriptionSettings={orgSubscriptionSettings}
                    setOrgSubscriptionSettings={setOrgSubscriptionSettings}
                    org={org} 
                    isOpen={true} 
                    onClose={() => setShowAdminPanelSettings(false)} 
                    dispatch={dispatch} 
                    setToastConfig={setToastConfig} 
                    toastConfig={toastConfig}
                />
            }
        </>

    )
}
