import { ReactNode } from "react"

// When uploading a info.json
export interface BucketUploadModel {
    columns: BucketTemplateColumn[],
    categorization_name: string,
    categorization: BucketTemplateCategory[],
    categorizations: BucketUploadCategory[]
}

export interface BucketTemplate {
    id: number,
    name: string,
    columns: BucketTemplateColumn[],
    categorization_name: string,
    categorization: BucketTemplateCategory[],
}

export interface BucketUploadCategory {
    name: string,
    categorization: BucketTemplateCategory[]
}

export interface BucketTemplateColumn {
    name: string,
    type: BucketColumnType

    // Standard values defined for categorization columns
    // Only for use in the template editor and does not represent the actual values present in cloud
    values: string[]
}

export interface BucketTemplateCategory {
    name: string,
    position: Position,
    is_custom_category: boolean,
}

export interface BucketTemplateFormulaColumn {
    name: string,
    formula: string,
    per_category: boolean,
    target_column: string,
    value_formulas: {[key: string]: string}
}

export enum BucketColumnType {
    Text = "text",
    ID = "id",
    Decimal = "decimal",
    Date = "date",
    Categorization = "categorization",
}

export interface Position {
    x: number,
    y: number
}

export interface Color {
    r: number,
    g: number,
    b: number
}

export interface ReportTemplateGroup {
    id: number,
    name: string,
    report_templates: ReportTemplate[]
}

export interface ReportTemplate {
    id: number,
    bucket_id: number,
    group_id: number,
    name: string,
    description: string,
    columns: string[],
    shown_columns: string[],
    kpis: ReportTemplateKPI[],
    view_settings: ReportViewSettings
    kpi_config: ReportTemplateKpiKpi[],
    fixed_slicers: string[],
    filters: ReportFilter[],
    limit: number,
    sort_column: string,
    sort_direction: SortDirection,

    // The name of the default group, if empty will use unsorted
    default_group: string
}

export type SortDirection = "" | "desc" | "asc"

export interface ReportTemplateKPI {
    column: string,
    aggregation: AggregationType,
    showKPIDecimals: boolean,
    showPercentage: false,
    trend: false
}

// For some reason they are named slightly differently than a normal KPI...
export interface ReportTemplateKpiKpi {
    shown: boolean,
    column?: string,
    aggregation?: AggregationType,
    showPercentages?: boolean,
    showDecimals?: boolean,
}

export interface ReportViewSettings {
    settings: {
        default_view: "overview" | "grid" | "kpis" | "sum" | "trend" | "profit" | "",
        overview: boolean,
        grid: boolean,
        kpis: boolean,
        sum: boolean,
        trend: boolean,
        profit: boolean,
        enableLabel: boolean,
    },
    actual: {
        overview: {
            selectedColumn: string,
            selectedAggregation: string,
            useDecimals: boolean,
            usePercentage: boolean,
            descriptions: ReportOverviewDescription[]
        }
    }
    trend?: {
        kpigraph: {
            selectedKpi1 :{
                kpi:{

                    column: string;
                    aggregation: string;
                    showKPIDecimals: boolean;
                    showPercentage: boolean;
                    trend: boolean;
                }
                color: string
                index: number
                settings: {
                    type: "bar" | "line"
                }
            }
            selectedKpi2 :{
                column: string;
                aggregation: string;
                showKPIDecimals: boolean;
                showPercentage: boolean;
                trend: boolean;
                settings: {
                    type: "bar" | "line"
                }
            } | null
            selectedCategory: string | null
            selectedKpiView: string
            goToDiff: boolean
            graphOptions: {
                zoom: number
                axisNum: number
                startAtZero: boolean
            }
        }
    }
}

export interface ReportOverviewDescription {
    position: Position,
    text: string[]
}

export interface ReportFilter {
    column: string,
    compare_type: CompareType,
    target_values: any[],
    is_column: boolean
}

export enum CompareType {
    EQ = 1,
    NEQ = 6,
    CONTAINS = 9,
    NCONTAINS = 12,
    STARTS = 7,
    ENDS = 8,
    NSTARTS = 10,
    NENDS = 11,

    GT = 4,
    GEQ = 3,
    LT = 5,
    LEQ = 2,

    BETWEEN = 13,
    NBETWEEN = 14,
}

export const getPossibleCompareTypes = (column: string, bucket: BucketTemplate, isColumn = false): SelectOption<CompareType>[] => {
    const col = bucket.columns.find(x => x.name === column)
    if (col === undefined) return [] as SelectOption<CompareType>[]
    return getCompareTypes(col.type, isColumn)
}

// export const getCompareTypes = (type: BucketColumnType, isColumn = false) => {
//     switch(type){
//         case BucketColumnType.ID:
//         case BucketColumnType.Text:
//         case BucketColumnType.Categorization:
//             return [
//                 {value: CompareType.EQ, label: "Equal"},
//                 {value: CompareType.NEQ, label: "Not equal"},
//                 {value: CompareType.CONTAINS, label: "Contains"},
//                 {value: CompareType.NCONTAINS, label: "Not contains"},
//                 {value: CompareType.STARTS, label: "Starts with"},
//                 {value: CompareType.ENDS, label: "Ends with"},
//                 {value: CompareType.NSTARTS, label: "Not starts with"},
//                 {value: CompareType.NENDS, label: "Not ends with"}
//             ]
//         case BucketColumnType.Decimal:
//             return [
//                 {value: CompareType.GT, label: "Greater"},
//                 {value: CompareType.GEQ, label: "Equal or greater"},
//                 {value: CompareType.LT, label: "Less"},
//                 {value: CompareType.LEQ, label: "Equal or less"},
//                 {value: CompareType.EQ, label: "Equal"},
//                 {value: CompareType.NEQ, label: "Not equal"}
//             ]
//         case BucketColumnType.Date:
//             return [
//                 {value: CompareType.EQ, label: "On"},
//                 {value: CompareType.NEQ, label: "Not on"},
//                 {value: CompareType.LT, label: "Before"},
//                 {value: CompareType.LEQ, label: "On or before"},
//                 {value: CompareType.GT, label: "After"},
//                 {value: CompareType.GEQ, label: "On or after"}
//             ]
//         default:
//             return []
//     }
// }

export const getCompareTypes = (type: BucketColumnType, isColumn = false): SelectOption<CompareType>[] => {
    switch(type){
        case BucketColumnType.ID:
        case BucketColumnType.Text:
        case BucketColumnType.Categorization:
            return isColumn ? [
                {value: CompareType.EQ, label: "Equal"},
                {value: CompareType.NEQ, label: "Not equal"}
            ] : [
                    {value: CompareType.EQ, label: "Equal"},
                    {value: CompareType.NEQ, label: "Not equal"},
                    {value: CompareType.CONTAINS, label: "Contains"},
                    {value: CompareType.NCONTAINS, label: "Not contains"},
                    {value: CompareType.STARTS, label: "Starts with"},
                    {value: CompareType.ENDS, label: "Ends with"},
                    {value: CompareType.NSTARTS, label: "Not starts with"},
                    {value: CompareType.NENDS, label: "Not ends with"}
                ]
        case BucketColumnType.Decimal:
            return isColumn ? [
                {value: CompareType.GT, label: "Greater"},
                {value: CompareType.GEQ, label: "Equal or greater"},
                {value: CompareType.LT, label: "Less"},
                {value: CompareType.LEQ, label: "Equal or less"},
                {value: CompareType.EQ, label: "Equal"},
                {value: CompareType.NEQ, label: "Not equal"},
            ] : [
                    {value: CompareType.GT, label: "Greater"},
                    {value: CompareType.GEQ, label: "Equal or greater"},
                    {value: CompareType.LT, label: "Less"},
                    {value: CompareType.LEQ, label: "Equal or less"},
                    {value: CompareType.EQ, label: "Equal"},
                    {value: CompareType.NEQ, label: "Not equal"},
                    {value: CompareType.BETWEEN, label: "Between"},
                    {value: CompareType.NBETWEEN, label: "Not between"}
                ]
        case BucketColumnType.Date:
            return isColumn ? [
                {value: CompareType.EQ, label: "On"},
                {value: CompareType.NEQ, label: "Not on"},
                {value: CompareType.LT, label: "Before"},
                {value: CompareType.LEQ, label: "On or before"},
                {value: CompareType.GT, label: "After"},
                {value: CompareType.GEQ, label: "On or after"},
            ] : [
                {value: CompareType.EQ, label: "On"},
                {value: CompareType.NEQ, label: "Not on"},
                {value: CompareType.LT, label: "Before"},
                {value: CompareType.LEQ, label: "On or before"},
                {value: CompareType.GT, label: "After"},
                {value: CompareType.GEQ, label: "On or after"},
                {value: CompareType.BETWEEN, label: "Between"},
                {value: CompareType.NBETWEEN, label: "Not between"}
            ]
        default:
            return []
    }
}

export enum AggregationType {
    // If type = decimal
    SUM = "sum",
    AVG = "avg",
    MIN = "min",
    MAX = "max",

    // All other types
    COUNT = "count",
}

export interface SelectOption<T> {
    value: T,
    label: string,
    icon?: ReactNode,
}

export const getPossibleAggTypes = (column: string, bucket: BucketTemplate): SelectOption<AggregationType>[] => {
    const col = bucket.columns.find(x => x.name === column)
    if (col === undefined) return [] as SelectOption<AggregationType>[]

    if (col.type === BucketColumnType.Decimal) {
        return [
            {
                label: "Sum",
                value: AggregationType.SUM
            },
            {
                label: "Avg",
                value: AggregationType.AVG
            },
            {
                label: "Min",
                value: AggregationType.MIN
            },
            {
                label: "Max",
                value: AggregationType.MAX
            },
        ]
    }

    return [{label: "Count", value: AggregationType.COUNT}]
}

export interface NewReportTemplate {
    name: string,
    description: string,
    group_id: number,
    bucket_id: number,
}

export const defaultNewReportTemplate: NewReportTemplate = {
    name: "",
    description: "",
    group_id: 0,
    bucket_id: 0,
}


export interface ActionTemplateGroup {
    id: number,
    name: string,
    action_templates: ActionTemplate[]
}

export interface ActionTemplate {
    id: number,
    bucket_id: number,
    group_id: number,
    name: string,
    description: string,
    columns: string[],
    shown_columns: string[],
    filters: ReportFilter[],
    limit: number,
    offset: number,
    sort_column: string,
    sort_direction: SortDirection,
    layout: ActionTemplateCategory[],
    column_filter: string[];
    selected_kpi: string
}

export type ActionCategoryColor = "btn-primary" | "btn-dark" | "btn-danger" | "btn-warning" | "btn-info" | "btn-success"
export const actionCategoryColors: ActionCategoryColor[] = ["btn-primary", "btn-dark", "btn-danger", "btn-warning", "btn-info", "btn-success"]
export interface ActionTemplateCategory{
    name: string;
    color: ActionCategoryColor
    donecount?: boolean;
}

export interface ActionTemplateLayoutWithTemplateName{
    template_name: string
    layout: ActionTemplateCategory[]
}
export interface NewActionTemplate {
    name: string,
    description: string,
    group_id: number,
    bucket_id: number,
}

export const defaultNewActionTemplate: NewActionTemplate = {
    name: "",
    description: "",
    group_id: 0,
    bucket_id: 0,
}