import { ReportingItem, Plan } from './../types/reporting_types';
import { ReportingStats } from "../types/reporting_types"
import { MailBundle } from '../types/cloud_types';

export const getReportingStats = (items: ReportingItem[], plans: Plan[]): ReportingStats => {
    return {
        daily: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "D").length,
        weekly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "W").length,
        monthly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "M").length
    }
}

export const getMailBundleStats = (items: MailBundle[], plans: Plan[]): ReportingStats => {
    return {
        daily: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "D").length,
        weekly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "W").length,
        monthly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "M").length
    }
}

export const getPlanInterval = (plan_id: number, plans: Plan[]): string => {
    var plan = plans.find(x => x.id === plan_id);
    if(plan !== undefined)
        return plan.interval
    else
        return ""
}