import {APAAccount, APASetup, ServerData} from './types'


export interface CloudServerOrganization {
    server: ServerData
    organizations: CloudOrganization[] | CloudOrgInfo[]
}


export interface CloudOrganization {
    id: number;
    name: string;
    deleted: boolean;
    server?: ServerData
}
export interface CloudOrgInfo extends CloudOrganization{
    user_count: number,
    bucket_count: number,
    fk_company_id: number,
    users?: CloudUser[],
    use_access_control: boolean,
    quarantined?: APASetup,
    account?: APAAccount,
    enable_report_templates: boolean,
    enable_action_templates: boolean,
    location_field_enabled?: boolean
    location_field_mandatory?: boolean
    self_subscription_enabled: boolean
}

export interface OrganizationSubscriptionSettings {
    org_id: number;
    enable_matrix: boolean;
    enable_kpi: boolean;
    enable_sum: boolean;
    enable_profit: boolean;
    enable_trend: boolean;
    enable_dashboard: boolean;
    enable_insight: boolean;
}

export const newOrganizationSubscriptionSettings = (orgID: number):OrganizationSubscriptionSettings => ({
    org_id: orgID,
    enable_matrix: false,
    enable_kpi: false,
    enable_sum: false,
    enable_profit: false,
    enable_trend: false,
    enable_dashboard: true,
    enable_insight: true,
})

export interface CloudDashboard {
    id: number;
    name: string;
}

export interface CloudUser {
    id?: number;
    organization_id?: number;
    firstname: string;
    lastname: string;
    email: string;
    permissions: string[];
    bucket_access: string[] | null;
    phone_number: string;
    department: string;
    language: string;
    job_title: string;
    deleted: boolean;
    last_login: number;
    location?: string
}

export interface CloudUserSession {
    id: number;
    user_id: number;
    organization_id: number;
    start_time: number;
    last_activity: number;
    requests: number;
    active: boolean;
}

export interface CloudItem {
    org_id: number;
    // dashboards props
    id?: number;
    title?: string;
    // reports props
    report_id?: number;
    name?: string;
    is_public?: boolean
}

export interface CloudDashboard extends CloudItem {
    id: number;
    title: string;
}

export interface CloudReport extends CloudItem {
    report_id: number;
    name: string;
    bucket: string;
}

export interface CloudDashboardMap {
    [key: number]: CloudDashboard;
}

export interface CloudReportMap {
    [key: number]: CloudReport;
}

export interface CloudUserMap{
    [key: number]: CloudUser
}

export interface CloudTrackedBucket {
    [key: string]: string | number | boolean;
    name: string;
    last_data_time: number;
    id: string;
    organization_id: number;
    organization_name: string;
}

export interface CloudTrackedBucketStatus extends CloudTrackedBucket {
    isTracked: boolean
}

export interface CloudError {
    user_agent: string;
    id: number;
    user_id: number;
    organization_id: number;
    time: number;
    error_type : number;
    url: string;
    comment: string;
    user_session: string;
    message: string;
    count: number;
    update_time: number;
    archived:boolean;
    software_version: string;
    http_method: string;
    http_code: number;
    endpoint: string;
    data: string;
    error_name: string;
    stack_trace: string;
    report_settings: string;
}

export interface ServerWrapper<T> {
    server: ServerData,
    value: T
}


export interface Consultant {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    user_id: number;
}

export interface MailBundle {
    id: number;
    name: string;
    org_id: number;
    plan: string;
    plan_id?: number;
    last_sent: number;
    active: boolean;
    link: boolean;
    email_text: string;
    email_title: string;
    combined_pdf: boolean;
    owner_id: number;
    self_subscription: boolean;
}

export interface MailBundleWithRelations extends MailBundle{
    user_ids : number[];
    dashboards : ReportingDashboard[];
    report_ids : number[];
    insight_views: InsightView[];
}

export interface ReportingDashboard {
    id?: number;
    dashboard_id: number;
    include_table: boolean;
    include_widgets: boolean;
}

export interface InsightView {
    id? : number;
    report_id: number;
    hash: string;
    type: "trend" | "matrix" | "kpi" | "sum" | "profit"
}

export const newMailBundle = (orgID : number) : MailBundleWithRelations => {
    return {
        id: 0,
        name: "",
        org_id: orgID,
        plan: "",
        last_sent: 0,
        active: false,
        link: true,
        email_text: "",
        email_title: "",
        combined_pdf: true,
        self_subscription: false,
        owner_id: -1,
        dashboards: [],
        insight_views: [],
        report_ids: [],
        user_ids: [],
    }
}
