import { Menu, Transition } from '@headlessui/react'
import CustomInput from '../components/CustomInput'
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { FaSave, FaUndo } from 'react-icons/fa'
import { HiChevronDown, HiOutlinePlus, HiOutlineUpload, HiTrash } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../components/Button'
import { Modal, WarningModal } from '../components/Modals'
import Select from '../components/Select'
import TabBar from '../components/TabBar'
import BucketTemplateRow from '../components/Template/BucketTemplateRow'
import { get, put, del } from '../helpers/Requests'
import { compareObjects } from '../helpers/type_helpers'
import { BucketColumnType, BucketTemplate, BucketTemplateCategory, BucketTemplateColumn, BucketUploadModel, Position } from '../types/template_types'
import CustomCheckbox from '../components/Buttons/CustomCheckbox'
import ShowIf from '../components/ShowIf'

const TemplateBucketEdit = () => {

    const [bucket, setBucket] = useState<BucketTemplate>()
    const [tmpBucket, setTmpBucket] = useState<BucketTemplate>()

    const [columnToAdd, setColumnToAdd] = useState<BucketTemplateColumn | undefined>(undefined)

    const [tmpCatIndex, setTmpCatIndex] = useState<number | undefined>()
    const [tmpCatName, setTmpCatName] = useState("")
    const [tmpCatPosition, setTmpCatPosition] = useState<Position>({x: 0, y: 0})
    const [tmpCatCustom, setTmpCatCustom] = useState(false)

    const [tab, setTab] = useState(0)

    const [showDeleteColumnWarning, setShowDeleteColumnWarning] = useState(false)
    const [columnToDelete, setColumnToDelete] = useState(-1)

    const [showDeleteCatWarning, setShowDeleteCatWarning] = useState(false)
    const [catToDelete, setCatToDelete] = useState(-1)

    const [showDeleteTemplateWarning, setShowDeleteTemplateWarning] = useState(false)

    const [showUploadDialog, setShowUploadDialog] = useState(false)
    const [modelFile, setModelFile] = useState<File | undefined>(undefined)

    const [tmpName, setTmpName] = useState("")
    const [showEditName, setShowEditName] = useState(false)

    const navigate = useNavigate();
    const { cloudId, bucketId } = useParams()

    useEffect(() => {
        get("cloud/" + cloudId + "/templates/bucket/" + bucketId).then(result => {
            setBucket(result)
        }).catch(err => console.log(err))
    }, [cloudId, bucketId])

    useEffect(() => {
        if (bucket === undefined) return
        let tmp: BucketTemplate = structuredClone(bucket)
        setTmpBucket(tmp)
    }, [bucket])

    const onDeleteTemplate = () => {
        setShowDeleteTemplateWarning(true)
    }

    const confirmDeleteTemplate = () => {
        del("cloud/" + cloudId + "/templates/bucket/" + bucketId).then(() => {
            navigate(`/templates/${cloudId}/buckets`)
        }).catch(err => console.log(err))
    }

    const onCloseEditName = () => {
        setShowEditName(false)
        setTmpName(tmpBucket?.name ?? "")
    }

    const onSaveName = () => {
        if (tmpName === "" || tmpBucket === undefined) return
        let tmp = structuredClone(tmpBucket)
        tmp.name = tmpName
        setTmpBucket(tmp)
        onCloseEditName()
    }

    const onSaveColumn = (id: number, column: BucketTemplateColumn) => {
        if (tmpBucket === undefined) return
        let tmp: BucketTemplate = structuredClone(tmpBucket)
        tmp.columns[id] = column
        setTmpBucket(tmp)
    }

    const onDeleteColumn = (id: number) => {
        setColumnToDelete(id)
        setShowDeleteColumnWarning(true)
    }

    const confirmDeleteColumn = () => {
        if (tmpBucket === undefined) return
        if (columnToDelete === -1) return
        let tmp: BucketTemplate = structuredClone(tmpBucket)
        if (tmp.categorization_name === tmp.columns[columnToDelete].name) {
            tmp.categorization_name = ""
        }
        tmp.columns.splice(columnToDelete, 1)
        setTmpBucket(tmp)
        setShowDeleteColumnWarning(false)
        setColumnToDelete(-1)
    }

    const confirmDeleteCategory = () => {
        if (tmpBucket === undefined) return
        if (catToDelete === -1) return
        let tmp: BucketTemplate = structuredClone(tmpBucket)
        tmp.categorization.splice(catToDelete, 1)
        setTmpBucket(tmp)
        setShowDeleteCatWarning(false)
        setCatToDelete(-1)
    }


    const onCancelDeleteColumn = () => {
        setShowDeleteColumnWarning(false)
        setColumnToDelete(-1)
    }

    const onCancelDeleteCategory = () => {
        setShowDeleteCatWarning(false)
        setCatToDelete(-1)
    }

    const addColumn = (column: BucketTemplateColumn) => {
        if (tmpBucket === undefined) return
        let tmp: BucketTemplate = structuredClone(tmpBucket)
        tmp.columns.push(column)
        setTmpBucket(tmp)
        setColumnToAdd(undefined)
    }

    const disableSave = () => {
        return compareObjects(tmpBucket, bucket)
    }

    const onSave = () => {
        if (tmpBucket === undefined) return
        for (let i = 0; i < tmpBucket.columns.length; i++) {
            if (tmpBucket.columns[i].type !== BucketColumnType.Categorization && tmpBucket.columns[i].values.length > 0) {
                tmpBucket.columns[i].values = []
            }
        }
        put("cloud/" + cloudId + "/templates/bucket", tmpBucket).then(result => {
            setBucket(result)
        }).catch(err => console.log(err))
    }

    const onUndo = () => {
        setTmpBucket(structuredClone(bucket))
    }

    const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (tmpBucket === undefined) return
        let file = e.target?.files?.[0]
        setModelFile(file)
    }

    const onFileUpload = () => {
        if (modelFile == null || tmpBucket === undefined) return
        modelFile.text().then(result => {
            const model: BucketUploadModel = JSON.parse(result).model
            let tmp: BucketTemplate = structuredClone(tmpBucket)
            tmp.columns = model.columns
            for (let i = 0; i < tmp.columns.length; i++) {
                tmp.columns[i].values = []
            }
            tmp.categorization_name = model.categorization_name
            tmp.categorization = []
            for (let c of model.categorization) {
                let cat: BucketTemplateCategory = {name: c.name, position: c.position, is_custom_category: c.is_custom_category}
                tmp.categorization.push(cat)
            }
            for (let c of model.categorizations) {
                let index = tmp.columns.findIndex(col => col.name === c.name)
                if (index === -1) {
                    continue
                }
                for (let cat of c.categorization) {
                    tmp.columns[index].values.push(cat.name)
                }
            }
            setTmpBucket(tmp)
            setModelFile(undefined)
            setShowUploadDialog(false)
        })

    }

    const onCategoryChange = (val: string) => {
        if (tmpBucket === undefined) return
        let tmp = structuredClone(tmpBucket)
        tmp.categorization_name = val
        setTmpBucket(tmp)
    }

    const onEditName = () => {
        setTmpName(tmpBucket?.name ?? "Name") 
        setShowEditName(true)
    }

    const editCategory = (cat: BucketTemplateCategory, index: number) => {
        setTmpCatIndex(index)
        setTmpCatName(cat.name)
        setTmpCatCustom(cat.is_custom_category)
        setTmpCatPosition(cat.position)
    }

    const onCatSaveChange = () => {
        if (tmpBucket === undefined || tmpCatIndex === undefined) return
        let tmp = structuredClone(tmpBucket)
        tmp.categorization[tmpCatIndex] = {position: tmpCatPosition, name: tmpCatName, is_custom_category: tmpCatCustom}
        setTmpBucket(tmp)
        setTmpCatIndex(undefined)
        setTmpCatName("")
    }

    const createCategory = (position: Position) => {
        if (tmpBucket === undefined) return
        setTmpCatIndex(tmpBucket.categorization.length)
        setTmpCatName("")
        setTmpCatCustom(true)
        setTmpCatPosition(position)
    }

    const removeCategory = (index: number) => {
        if (tmpBucket === undefined) return
        let tmp = structuredClone(tmpBucket)
        tmp.categorization.splice(index, 1)
        setTmpBucket(tmp)
    }

    const EmptyCategoryCard = ({position}: {position: Position}) => {
        return <div onClick={() => createCategory(position)} className='flex justify-center items-center w-[200px] h-[100px] border rounded-xl overflow-hidden bg-gray-100 hover:bg-almondine-mist-400 cursor-pointer'>
            <HiOutlinePlus /> Add category
        </div>
    }

    const CategoryCard = ({cat, index}: {cat: BucketTemplateCategory, index: number}) => {
        return <div onClick={() => editCategory(cat, index)} className='relative flex flex-col items-center w-[200px] h-[100px] border rounded-xl overflow-hidden bg-white hover:bg-almondine-mist-400 cursor-pointer' title='Click to edit'>
            <HiTrash className='absolute right-2 top-2 text-white hover:text-red-500' title='Remove' onClick={(e) => {e.stopPropagation(); removeCategory(index)}} />
            <div className="bg-[#5d5959] w-full text-white text-center h-[40px] flex items-center justify-center">
                {cat.name}
            </div>
            <ShowIf if={cat.is_custom_category}>
                <span className='text-sm opacity-50'>Custom</span>
            </ShowIf>
        </div>
    }

    if (tmpBucket === undefined) return <></>
    
    const categoryColumns = tmpBucket.columns?.filter(v => v.type === BucketColumnType.Categorization) ?? []

    let gridHeight = tmpBucket.categorization.map(c => c.position?.y ?? 0).reduce((a, b) => Math.max(a, b), 2)

    const categoryGrid: ReactNode[][] = []
    for (let row = 0; row <= (gridHeight + 1); row++) {
        categoryGrid[row] = []
        for (let col = 0; col < 3; col++) {
            categoryGrid[row][col] = <EmptyCategoryCard position={{x: col, y: row}} key={`${row}-${col}-Empty`} />
        }
    }
    tmpBucket.categorization.forEach((c, i) => {
        if (c.position === undefined) c.position = {x: 0, y: 0}
        categoryGrid[c.position.y][c.position.x] = <CategoryCard index={i} cat={c} key={`${c.position.y}-${c.position.x}`} />
    })

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 onClick={onEditName} className="cursor-pointer text-gray-500 text-lg font-semibold">Bucket Setup - {tmpBucket?.name ?? "Loading"}</h1></div>
            <Menu as="div" className="relative inline-block text-left mx-4">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    Options
                    <HiChevronDown
                      className="ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={onEditName}
                          >
                            Edit name
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-red-600 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={onDeleteTemplate}
                          >
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                      </div>
                  </Menu.Items>
                </Transition>
              </Menu>
        </div>
        <div className="mx-4 flex flex-col gap-4">
            {
                tmpBucket === undefined ? <h1>Loading...</h1>
                : <>
                <div className='flex gap-2 justify-between'>
                    <div className='flex gap-2'>
                        <Button onClick={onSave} disabled={disableSave()} icon={<FaSave />} text='Save' color='primary' className='w-fit' />
                        <Button onClick={onUndo} disabled={disableSave()} icon={<FaUndo />} text='Undo' color='secondary' className='w-fit' />
                    </div>
                    <Button onClick={() => setShowUploadDialog(true)} icon={<HiOutlineUpload />} text='Upload model' color='secondary' className='w-fit' />
                </div>
                <TabBar setTab={i => setTab(i)} tabs={["Columns", "Categorization"]} selected={tab}/>
                {tab === 0 && <>
                <div className='flex flex-col border rounded-lg divide-y divide-gray-200 overflow-auto'>
                    <div style={{justifyContent: "stretch"}} className='flex uppercase text-xs font-medium tracking-wider'>
                        <div className='w-full px-4 py-3'>Name</div>
                        <div className='w-full px-4 py-3'>Type</div>
                        <div className='shrink-0 w-[54px]' />
                    </div>
                    <div className='bg-white flex flex-col divide-y divide-gray-200'>
                        {tmpBucket.columns?.map((col, i) => <BucketTemplateRow key={i} id={i} setTab={setTab} isActiveCategory={col.name === tmpBucket.categorization_name} column={col} onDeleteColumn={onDeleteColumn} onSaveColumn={onSaveColumn}  />)}
                        {columnToAdd ? <BucketTemplateRow focus id={-1} column={columnToAdd} setTab={setTab} onSaveColumn={(_, col) => addColumn(col)} onDeleteColumn={() => setColumnToAdd(undefined)} onCancel={() => setColumnToAdd(undefined)} /> : null}
                    </div>
                </div>
                <Button onClick={() => setColumnToAdd({name: "New Column", type: BucketColumnType.Text, values: []})} icon={<HiOutlinePlus />} text='Add column' color='primary' />

                </>
                }

                {tab === 1 && <>
                    <Select label='Current active categorization column' value={tmpBucket.categorization_name} onChange={e => onCategoryChange(e.target.value)}>
                        <option value={""}>None</option>
                        {
                            categoryColumns.map((c, i) => <option key={i} value={c.name}>{c.name}</option>)
                        }
                    </Select>

                    <div className='flex flex-col divide-y divide-gray-200 overflow-auto'>
                        <div className='grid grid-cols-3 w-fit gap-2'>
                                    {
                                        categoryGrid
                                    }
                        </div>
                    </div>
                </>}
                </>
            }

        </div>
        <WarningModal
            isOpen={showDeleteColumnWarning}
            title='Delete column'
            actionText='Delete'
            closeText='Cancel'
            onClose={onCancelDeleteColumn}
            onAction={confirmDeleteColumn}
            >
            Are you sure you want to delete this column?
        </WarningModal>
        <WarningModal
            isOpen={showDeleteCatWarning}
            title='Delete category'
            actionText='Delete'
            closeText='Cancel'
            onClose={onCancelDeleteCategory}
            onAction={confirmDeleteCategory}
            >
            Are you sure you want to delete this column?
        </WarningModal>
        <WarningModal
            isOpen={showDeleteTemplateWarning}
            title='Delete bucket template'
            actionText='Delete'
            closeText='Cancel'
            onClose={() => setShowDeleteTemplateWarning(false)}
            onAction={confirmDeleteTemplate}
            >
            Are you sure you want to delete this template? <span className='text-red-600 font-bold'>This is permanent and cannot be undone.</span>

        </WarningModal>
        <Modal 
            isOpen={showUploadDialog}
            closeText='Cancel'
            actionText='Apply'
            onClose={() => {setShowUploadDialog(false); setModelFile(undefined)}}
            onAction={onFileUpload}
            title='Upload model'
            disableAction={modelFile === undefined}
            >
            <p className='mt-3'>Upload a predefined model to be used. The file is a <code className='rounded bg-gray-200 px-1'>.json</code> file in this format:</p>
            <pre className='rounded bg-gray-200 px-1'>
                <code >
{
`{
    "model": {
        "columns": [
            {
                "name": "Example",
                "type": "text"
            },
            ...
        ],
        "categorization_name": "Example",
        "categorization": [
            {
                "name": "Example"
            },
            ...
        ]
    }
}
`
}
                </code>
            </pre>
            <span>Any extra fields will be ignored.</span>
            <br className='my-2'/>
            <input onChange={e => onFileChanged(e)} accept='.json' type="file" />
        </Modal>

        <Modal isOpen={showEditName} 
            onClose={onCloseEditName} 
            onAction={onSaveName} 
            title='Edit name' 
            actionText='Save' 
            closeText='Cancel'
            size='small'
            disableAction={tmpName === ""}>
            <div className='mt-3'>
                <label htmlFor='name-input'>Name</label>
                <CustomInput id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
            </div>
        </Modal>

        <Modal
            isOpen={tmpCatIndex !== undefined}
            onClose={() => setTmpCatIndex(undefined)}
            onAction={onCatSaveChange}
            title='Edit category'
            actionText='Save'
            closeText='Cancel'
            size='small'
            disableAction={tmpCatName === ""}
        >
            <div className='mt-3'>
                <label htmlFor='name-input'>Name</label>
                <CustomInput id='name-input' value={tmpCatName} onChange={e => setTmpCatName(e.target.value)} placeholder='Insert name...' />
                <CustomCheckbox value={tmpCatCustom} onChange={v => setTmpCatCustom(v)} text='Is custom' title='Is this a custom category or one from a ABC matrix' />
            </div>
        </Modal>

    </div>

}

export default TemplateBucketEdit
