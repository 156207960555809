import { useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { Modal } from "../components/Modals";
import { BucketInfo, saveBucketSetup } from "../types/bucket_types";
import CustomInput from "../components/CustomInput";
import { get, post } from "../helpers/Requests";
import { BucketTemplate } from "../types/template_types";
import Select from "../components/Select";
import Button from "../components/Button";
import { FaSave, FaUndo } from "react-icons/fa";
import { compareObjects } from "../helpers/type_helpers";
import { useParams } from "react-router-dom";

interface OrganizationBucketSetupEditorProps {
    chosenBucket: BucketInfo;
    orgId: number;
    cloudId: number;
    templates: BucketTemplate[];
    getBucket: () => void;
}

const OrganizationBucketSetupEditor = ({
    chosenBucket,
    orgId,
    templates,
    cloudId,
    ...props
}: OrganizationBucketSetupEditorProps) => {
    const [bucket, updateBucket] = useImmer(structuredClone(chosenBucket));
    const [loadingCounter, setLoadingCounter] = useState(0);
    const addLoading = () => setLoadingCounter((c) => c + 1);
    const doneLoading = () => setLoadingCounter((c) => Math.max(0, c - 1));
    const loading = loadingCounter > 0;

    const [showEditName, setShowEditName] = useState(false);
    const [tmpName, setTmpName] = useState("");

    const onEditName = () => {
        setTmpName(bucket.name);
        setShowEditName(true);
    };

    const hasChanges = useMemo(() => {
        return !compareObjects(bucket.setup, chosenBucket.setup);
    }, [bucket.setup, chosenBucket]);

    const onSaveName = () => {
        if (tmpName === "") return;
        const name = tmpName;
        addLoading();
        post(`cloud/${cloudId}/buckets/${orgId}/${bucket.id}`, {
            name: name,
            trendsets: bucket.trendsets,
        })
            .then(() => {
                updateBucket((b) => {
                    b.name = name;
                });
                props.getBucket();
            })
            .catch((e) => console.log(e))
            .finally(() => doneLoading());
        setShowEditName(false);
    };

    const onSave = () => {
        addLoading();
        saveBucketSetup(bucket, cloudId, orgId)
            .then(() => {
                props.getBucket();
                doneLoading();
            })
            .catch((e) => console.log(e));
    };

    const onUndo = () => {
        updateBucket(structuredClone(chosenBucket));
    };

    return (
        <>
            <button onClick={() => console.log(templates)}>DEBUG</button>

            <div className={loading ? "pointer-events-none opacity-50" : ""}>
                <div className="flex gap-2">
                    <Button
                        onClick={onSave}
                        icon={<FaSave />}
                        text="Save"
                        color="primary"
                        className="w-fit"
                        disabled={!hasChanges}
                    />
                    <Button
                        onClick={onUndo}
                        disabled={!hasChanges}
                        icon={<FaUndo />}
                        text="Undo"
                        color="secondary"
                        className="w-fit"
                    />
                </div>
                <div className="py-2">
                    <h1
                        onClick={onEditName}
                        title="Edit name"
                        className="cursor-pointer text-gray-500 text-lg font-semibold"
                    >
                        Bucket Setup - {bucket.name}
                    </h1>
                    <h2 className="text-gray-500 opacity-50 text-sm">
                        {bucket.id}
                    </h2>
                </div>
                <Select
                    value={bucket.setup.template_id}
                    onChange={(v) =>
                        updateBucket((b) => {
                            b.setup.template_id = Number(v.target.value);
                        })
                    }
                >
                    <option value={0}>None</option>
                    {templates.map((t) => (
                        <option key={t.id} value={t.id}>
                            {t.name}
                        </option>
                    ))}
                </Select>
            </div>

            <Modal
                isOpen={showEditName}
                onClose={() => setShowEditName(false)}
                onAction={onSaveName}
                title="Edit name"
                actionText="Save"
                closeText="Cancel"
                size="small"
                disableAction={tmpName === ""}
            >
                <div className="mt-3">
                    <label htmlFor="name-input">Name</label>
                    <CustomInput
                        id="name-input"
                        value={tmpName}
                        onChange={(e) => setTmpName(e.target.value)}
                        placeholder="Insert name..."
                    />
                </div>
            </Modal>
        </>
    );
};

export default OrganizationBucketSetupEditor;

export const OrganizationBucketSetupEditorWrapper = () => {
    const { cloudId, orgId, bucketId } = useParams();
    const [bucket, setBucket] = useState<BucketInfo>();
    const [templates, setTemplates] = useState<BucketTemplate[]>();
    useEffect(() => {
        getBucket();
    }, [cloudId, orgId, bucketId]);

    useEffect(() => {
        getTemplates();
    }, []);
    if (cloudId === undefined || orgId === undefined || bucketId === undefined)
        return (
            <h1>
                Something went wrong, missing parameters in URL - cloudId:{" "}
                {cloudId} - orgId: {orgId} - bucketId: {bucketId}
            </h1>
        );

    const getBucket = () => {
        get(`cloud/${cloudId}/buckets/${orgId}/${bucketId}`)
            .then((b: BucketInfo) => {
                setBucket(b);
            })
            .catch((e) => console.log(e));
    };

    const getTemplates = () => {
        get("cloud/" + cloudId + "/templates/buckets")
            .then((result) => {
                let tmpBuckets: BucketTemplate[] = result;
                setTemplates(tmpBuckets);
            })
            .catch((err) => console.log(err));
    };

    return bucket === undefined || templates === undefined ? (
        <>
            <h1>Loading...</h1>
        </>
    ) : (
        <OrganizationBucketSetupEditor
            chosenBucket={bucket}
            getBucket={getBucket}
            cloudId={Number(cloudId)}
            orgId={Number(orgId)}
            templates={templates}
        />
    );
};
