import React, { Dispatch, SetStateAction } from "react";
import { ReportingItemStats } from "./ReportingItem";
import { CloudUser, MailBundleWithRelations } from "../../types/cloud_types";
import { Dashboard, DashboardTable, Report, ReportingStats, TrendView } from "../../types/reporting_types";
import CustomInput from "../CustomInput";
import Select from "react-select"
import { FaUser, FaUsers } from "react-icons/fa";
import { MdClose } from "react-icons/md";

interface ReportingFilterBarProps{
    items: MailBundleWithRelations[] | DashboardTable[] | Dashboard[] | TrendView[] | Report[]
    stats: ReportingStats
    selectedPlan: "D" | "W" | "M" | "A"
    setSelectedPlan: Dispatch<SetStateAction<"D" | "W" | "M" | "A">>
    users: CloudUser[]
    userFilter: number | undefined
    setUserFilter: Dispatch<SetStateAction<number |undefined>>
    setShowAddModal: Dispatch<SetStateAction<boolean>>
    shownItems: MailBundleWithRelations[] | DashboardTable[] | Dashboard[] | TrendView[] | Report[]
    searchText: string
    setSearchText: Dispatch<SetStateAction<string>>
    buttonColor?: "dashboard" | "dashboardtable" |"trendview" | "report";
    isMailbundle?: boolean
    subscriptionTypeFilter?: "BUNDLE" | "SUBSCRIPTION" | "A"
    setSubscriptionTypeFilter?: Dispatch<SetStateAction<"BUNDLE" | "SUBSCRIPTION" | "A">>
}

function ReportingFilterBar(props:ReportingFilterBarProps) {
    return (
        <div className="flex xl:flex-row flex-col justify-between xl:items-end mb-4 gap-2">
            <div className="flex flex-row items-center gap-4 h-9">
                <span className="font-semibold mb-1">
                    Filters: 
                </span>
                <div className="flex flex-row gap-6">
                    <ReportingItemStats 
                        color={"text-blue-500"} 
                        count={props.items.length} 
                        stats={props.stats} 
                        selectedPlan={props.selectedPlan}
                        setSelectedPlan={props.setSelectedPlan}
                    />
                </div>
                <div className="flex items-center">
                    <div className=" ml-2">
                        <Select
                            isClearable={true}
                            value={
                                props.users
                                    .filter(u => !u.deleted)
                                    .map(u => ({
                                        value: u.id,
                                        label: u.firstname + " " + u.lastname
                                    }))
                                    .find(o => o.value === props.userFilter)
                            }
                            options={props.users
                                .filter(u => !u.deleted)
                                .map(u => ({
                                    value: u.id ?? -1,
                                    label: u.firstname + " " + u.lastname
                                }))
                            }
                            onChange={(o) => props.setUserFilter(o?.value)}
                            isSearchable={true}
                            className="w-56"
                            placeholder="Select recipient..."
                        />
                    </div>
                </div>
                {props.isMailbundle && props.setSubscriptionTypeFilter && props.subscriptionTypeFilter &&
                    <div className="border-l p-1 flex gap-2">
                        <div 
                            title={props.subscriptionTypeFilter === "BUNDLE" ? "Show all" : "Show bundles"}
                            className={`${props.subscriptionTypeFilter === "BUNDLE" ? "text-white bg-gray-500" : "text-gray-500"} select-none cursor-pointer ml-4 border rounded-md lg:w-28 border-gray-400 flex items-center justify-between gap-1 text-sm`}
                            onClick={() => props.setSubscriptionTypeFilter ? props.subscriptionTypeFilter !== "BUNDLE" ? props.setSubscriptionTypeFilter("BUNDLE") : props.setSubscriptionTypeFilter("A") : null}
                        >
                            <span className="flex items-center gap-1 w-full justify-center lg:ml-2 lg:p-0 p-1"><FaUsers className="text-sm" /><span className="lg:inline-block hidden">Bundles</span></span>
                            <MdClose className={`${props.subscriptionTypeFilter === "BUNDLE" ? "" : "text-transparent"} text-xs mr-1 lg:inline-block hidden`} />
                        </div>
                        <div 
                            title={props.subscriptionTypeFilter === "SUBSCRIPTION" ? "Show all" : "Show subscriptions"}
                            className={`${props.subscriptionTypeFilter === "SUBSCRIPTION" ? "text-white bg-gray-500" : "text-gray-500"} select-none cursor-pointer border rounded-md lg:w-48 text-gray-500 border-gray-400 flex items-center justify-between gap-1 text-sm`}
                            onClick={() => props.setSubscriptionTypeFilter ? props.subscriptionTypeFilter !== "SUBSCRIPTION" ? props.setSubscriptionTypeFilter("SUBSCRIPTION") : props.setSubscriptionTypeFilter("A") : null}
                        >
                            <span className="flex items-center gap-1 w-full justify-center lg:ml-2 lg:p-0 p-1"><FaUser className="text-xs" /><span className="lg:inline-block hidden">Personal Subscriptions</span></span>   
                            <MdClose className={`${props.subscriptionTypeFilter === "SUBSCRIPTION" ? "" : "text-transparent"} text-xs mr-1 lg:inline-block hidden`} />
                        </div>
                    </div>
                }
            </div>
            <div className="flex flex-col gap-1 items-end w-96">
                <div className="flex w-full items-center gap-2">
                    <div className="text-sm w-fit flex-shrink-0 text-gray-500">
                        Showing {props.shownItems.length} of {props.items.length}
                    </div>
                    <CustomInput className="h-10" search={true} onChange={e => props.setSearchText(e.target.value)} value={props.searchText} placeholder="Search..." />
                </div>
            </div>
        </div>)
}

export default ReportingFilterBar