import { useState } from "react"
import { BsFileEarmark } from "react-icons/bs"
import { HiChevronDown, HiChevronRight, HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi"
import { Link } from "react-router-dom"
import { ReportTemplateGroup } from "../../types/template_types"

interface ReportTemplateGroupListProps {
    group: ReportTemplateGroup,
    className?: string,
    onNewTemplate: (id: number) => void,
    onDeleteGroup: (id: number) => void,
    onEditName: (id: number) => void
}

const ReportTemplateGroupList = ({className = "", group, ...props}: ReportTemplateGroupListProps) => {
    const [show, setShow] = useState(group.report_templates.length > 0)

    const getChevron = () => {
        return show ? <HiChevronDown className="w-[18px] h-[18px]" /> : <HiChevronRight className="w-[18px] h-[18px]" /> 
    }

    return <div className={`flex flex-col gap-2 ${className}`}>
        <div onClick={() => setShow(s => !s)} className="cursor-pointer flex items-center justify-between w-[300px] border-b-2 border-b-black ">
            <h2 className="truncate">{group.report_templates.length} | {group.name}</h2>
            <div className="flex gap-1">
                <button className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-dark-forest-500 text-white  bg-dark-forest-600 text-sm rounded" title="Edit name" onClick={(e) => {e.stopPropagation(); props.onEditName(group.id)}} ><HiOutlinePencil /></button>
                <button className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-green-500 text-white  bg-green-600 text-sm rounded" title="New template" onClick={(e) => {e.stopPropagation(); props.onNewTemplate(group.id)}} ><HiOutlinePlus /></button>
                <button className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-red-500 text-white  bg-red-600 text-sm rounded" title="Delete group" onClick={(e) => {e.stopPropagation(); props.onDeleteGroup(group.id)}} ><HiOutlineTrash /></button>
                {getChevron()}
            </div>
        </div>
        {
            show ? <div className="flex flex-col gap-2">
            {group.report_templates.map((r, i) => {
                return <Link key={i} to={`${r.id}`} style={{width: "fit-content"}}>
                    <div className="flex rounded-md shadow-md p-3 bg-white hover:bg-neutral-50 items-center w-[400px]">
                        <BsFileEarmark className="w-[18px] h-[18px] mr-2" /> <span className="truncate">{r.name}</span>
                    </div>
                </Link>
            })}
            </div>: null
        }
    </div>
}

export default ReportTemplateGroupList
